import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import styled from "styled-components"
import { HandleOnChangeInput } from "../../helpers/formInput/HandleOnChangeInput"
import { notifyFailure } from "../../helpers/notifications/notifyFailure"
import { notifyInfo } from "../../helpers/notifications/notifyInfo"
import {
    hideLoginModal,
    hideResetPasswordModal,
    showLoginModal,
} from "../../store/actions/modalActions"
import { resetPassword } from "../../store/actions/userActions"
import { FormComponent } from "../Authentication/components/FormElement"
import { InputComponent } from "../Authentication/components/InputELement"
import { Button } from "../Global/Button"
import { SizedBox } from "../Global/SizedBox"
import { Spinner } from "../Global/Spinner"

const StyledComponent = styled.div`
    width: 100%;
    min-width: 400px;
    margin: 0 auto;

    form {
        margin-top: 0rem;

        .inputOuter {
            width: 100%;
            input {
                margin-bottom: 0.3rem;
                max-width: 100%;
            }
        }
        .actionButtonWrapper {
            display: flex;
            justify-content: flex-end;
            margin-top: 2rem;
        }
    }
`

export const ResetPassword = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [resetToken, setResetToken] = useState(null)
    const { search, pathname } = location

    useEffect(() => {
        dispatch(hideLoginModal())
        if (pathname.split("/")[2] === "finalize_reset_password_email") {
            setResetToken(search.split("=")[1])
        }
    }, [pathname, search, dispatch])

    // pathname, search,dispatch added in above dep

    const [data, setData] = useState({
        password: "",
        confirm_password: "",
    })

    // checking if user gets registered
    const info = useSelector((state) => state.resetPassword)
    const { loading, error } = info

    // notifying if error from reducer state
    error && notifyFailure(error)

    // validating fields
    const validateFields = () => {
        let state = true
        let fields = ["password", "confirm_password"]
        for (let field of fields) {
            if (!data[field]) {
                notifyFailure(`${field} is required`)
                state = false
            }
        }
        if (data.password !== data.confirm_password) {
            notifyFailure(`Your passwords doesn't match`)
            state = false
        }
        return state
    }

    // handling sign up button
    const handleResetPassword = async (e) => {
        e.preventDefault()
        // console.log("data", data);
        if (!validateFields()) {
            return
        }

        dispatch(resetPassword({ ...data, token: resetToken }))
        notifyInfo("Please login to proceed.")
        dispatch(hideResetPasswordModal())
        dispatch(showLoginModal())
    }

    return (
        <StyledComponent>
            <FormComponent className="formFieldWrapper" autocomplete="off">
                <h2>Reset Password</h2>
                <SizedBox height={2} />
                <div className="inputOuter">
                    <label>New Password</label>
                    <InputComponent
                        type="password"
                        fontSize={0.7}
                        value={data?.password}
                        onChange={(e) => {
                            HandleOnChangeInput(e, "password", setData, data)
                        }}
                    />
                </div>
                <div className="inputOuter">
                    <label>Confirm Password</label>
                    <InputComponent
                        type="password"
                        fontSize={0.7}
                        value={data?.confirm_password}
                        onChange={(e) => {
                            HandleOnChangeInput(
                                e,
                                "confirm_password",
                                setData,
                                data
                            )
                        }}
                    />
                </div>
                <div className="actionButtonWrapper">
                    {loading ? (
                        <Spinner size={1.5} />
                    ) : (
                        <Button
                            textTransform={"uppercase"}
                            fontSize={16}
                            maxWidth={200}
                            border={"transparent"}
                            height={41}
                            onClick={handleResetPassword}
                        >
                            Proceed
                        </Button>
                    )}
                </div>
            </FormComponent>
        </StyledComponent>
    )
}

import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import Contacts from "../components/Contacts"
import ChatContainer from "../components/ChatContainer"
import { useSelector } from "react-redux"
import { fetchMessages } from "../../../store/actions/chatAction"
import { useDispatch } from "react-redux"

function Chat() {
    const dispatch = useDispatch()
    const [currentUser, setCurrentUser] = useState()
    const [currentChat, setCurrentChat] = useState(undefined)
    const socket = useRef()

    useEffect(() => {
        const setUser = async () => {
            setCurrentUser({
                username: "temp",
                email: "temp@mail.com",
                isAvatarImageSet: false,
                _id: 1,
                password: "0",
                __v: 1,
                avatarImage: "favicon.ico",
            })
        }
        setUser()
    }, [])

    const allusers = useSelector((state) => state.allUsers)
    const { usersList } = allusers

    const contacts = usersList.map((user) => {
        return {
            avatarImage: user.picture?.file,
            email: user.email,
            _id: user.id,
            username: user.username,
        }
    })

    useEffect(() => {
        if (currentUser) {
            dispatch(fetchMessages())
        }
    }, [currentUser, dispatch])

    const handleChatChange = (chat) => {
        setCurrentChat(chat)
    }

    return (
        <>
            <Container>
                <div className="container">
                    <Contacts
                        contacts={contacts}
                        currentUser={currentUser}
                        changeChat={handleChatChange}
                    />
                    {currentChat === undefined ? (
                        <></>
                    ) : (
                        <ChatContainer
                            currentChat={currentChat}
                            currentUser={currentUser}
                            socket={socket}
                        />
                    )}
                </div>
            </Container>
        </>
    )
}

const Container = styled.div`
    height: -webkit-fill-available;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    background-color: #faf9f6;
    .container {
        height: 100vh;
        width: 100vw;
        background-color: #faf9f6;
        display: grid;
        grid-template-columns: 20% 80%;

        @media screen and (min-width: 720px) {
            grid-template-columns: 35% 65%;
            grid-template-rows: none;
            width: 85vw;
            height: 100vh;
        }
        @media screen and (min-width: 1100px) {
            grid-template-columns: 28% 72%;
        }
    }
`

export default Chat

import React from "react"
import { hideNotificationModal } from "../../store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { ModalComponent } from "../Global/Modal"
import { CloseBtn } from "../Global/CloseBtn"
import { NotificationSinglePage } from "../notification/NotificationSinglePage"

const NotificationModal = () => {
  const dispatch = useDispatch()
  const modalInfo = useSelector((state) => state.NotificationModal)
  const { show, notificationgetId } = modalInfo
  function closeModal() {
    dispatch(hideNotificationModal())
  }

  return (
    <ModalComponent
      isOpen={show}
      onRequestClose={closeModal}
      modalLabel={"Blog Modal"}
      classNameFromProps='blog_modal'
    >
      <>
        <NotificationSinglePage id={notificationgetId && notificationgetId} />
        <CloseBtn handleOnClickClose={closeModal} />
      </>
    </ModalComponent>
  )
}

export default NotificationModal

export const FETCH_USER_PROFILE_START = "FETCH_USER_PROFILE_START"
export const FETCH_USER_PROFILE_FAILED = "FETCH_USER_PROFILE_FAILED"
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS"
export const CLEAR_USER_PROFILE = "CLEAR_USER_PROFILE"

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST"
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS"
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL"
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET"

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGIN_RESET = "USER_LOGIN_RESET"

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"
export const USER_REGISTER_RESET = "USER_REGISTER_RESET"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"

export const USER_CHANGE_PASS_START = "USER_CHANGE_PASS_START"
export const USER_CHANGE_PASS_SUCCESS = "USER_CHANGE_PASS_SUCCESS"
export const USER_CHANGE_PASS_FAIL = "USER_CHANGE_PASS_FAIL"

export const USER_RESET_PASS_START = "USER_RESET_PASS_START"
export const USER_RESET_PASS_SUCCESS = "USER_RESET_PASS_SUCCESS"
export const USER_RESET_PASS_FAIL = "USER_RESET_PASS_FAIL"

export const USER_FORGOT_PASS_START = "USER_FORGOT_PASS_START"
export const USER_FORGOT_PASS_SUCCESS = "USER_FORGOT_PASS_SUCCESS"
export const USER_FORGOT_PASS_FAIL = "USER_FORGOT_PASS_FAIL"

export const USER_VERIFY_EMAIL_STEP1_START = "USER_VERIFY_EMAIL_START"
export const USER_VERIFY_EMAIL_STEP1_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS"
export const USER_VERIFY_EMAIL_STEP1_FAIL = "USER_VERIFY_EMAIL_FAIL"

export const USER_VERIFY_EMAIL_STEP2_START = "USER_VERIFY_EMAIL_START"
export const USER_VERIFY_EMAIL_STEP2_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS"
export const USER_VERIFY_EMAIL_STEP2_FAIL = "USER_VERIFY_EMAIL_FAIL"

export const ALL_USER_FETCH_START = "ALL_USER_FETCH_START"
export const ALL_USER_FETCH_SUCCESS = "ALL_USER_FETCH_SUCCESS"
export const ALL_USER_FETCH_FAILED = "ALL_USER_FETCH_FAILED"

import React, { useState } from "react"
import styled from "styled-components"
import Picker, { Theme } from "emoji-picker-react"
import { BsEmojiSmileFill } from "react-icons/bs"
import { IoMdSend } from "react-icons/io"

const ChatInput = ({ handleSendMessage }) => {
    const [msg, setMsg] = useState("")
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const handleEmojiPickerhideShow = () => {
        setShowEmojiPicker(!showEmojiPicker)
    }

    const handleEmojiClick = (emojiObject, event) => {
        let message = msg
        message += emojiObject.emoji
        setMsg(message)
    }

    const sendChat = (event) => {
        event.preventDefault()
        if (msg.length > 0) {
            handleSendMessage(msg, "")
            setMsg("")
        }
    }

    return (
        <Container>
            <div className="button-container">
                <div className="emoji">
                    <BsEmojiSmileFill onClick={handleEmojiPickerhideShow} />
                    {showEmojiPicker && (
                        <div className="emoji-picker-react">
                            <Picker
                                onEmojiClick={handleEmojiClick}
                                theme={Theme.DARK}
                            />
                        </div>
                    )}
                </div>
            </div>
            <form
                className="input-container"
                onSubmit={(event) => sendChat(event)}
            >
                <input
                    type="text"
                    placeholder="type your message here"
                    onChange={(e) => setMsg(e.target.value)}
                    value={msg}
                />

                <button type="submit">
                    <IoMdSend />
                </button>
            </form>
        </Container>
    )
}

const Container = styled.div`
    /* display: grid; */
    align-items: center;
    background-color: #d3d3d3;
    border-top: 0.2px solid #ffffff15;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    align-content: space-between;
    .button-container {
        display: flex;
        align-items: center;
        color: white;
        padding-right: 1rem;
        .emoji {
            position: relative;
            svg {
                font-size: 1.5rem;
                color: #ffff00c8;
                cursor: pointer;
            }
            .emoji-picker-react {
                position: absolute;
                top: -470px;
                border-color: #9a86f3;
                border-radius: 5%;
                .emoji-scroll-wrapper::-webkit-scrollbar {
                    background-color: #080420;
                    width: 5px;
                    &-thumb {
                        background-color: #9a86f3;
                    }
                }
                .emoji-categories {
                    button {
                        filter: contrast(0);
                    }
                }
                .emoji-search {
                    background-color: transparent;
                    border-color: #9a86f3;
                }
                .emoji-group:before {
                    background-color: #080420;
                }
            }
        }
    }
    .input-container {
        width: 100%;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        /* background-color: #ffffff34; */
        input {
            width: 90%;
            height: 60%;
            background-color: transparent;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 2rem;

            font-size: 1.1rem;
            background-color: #ffffff34;
            &::selection {
                background-color: #9a86f3;
            }
            &:focus {
                outline: none;
            }
        }

        button {
            padding: 0.1rem 0.1rem;
            border-radius: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            @media screen and (min-width: 720px) {
                padding: 0.2rem 0.2rem;

                svg {
                    font-size: 1rem;
                }
            }
            svg {
                font-size: 2rem;
                color: rgb(0, 135, 255);
            }
        }
    }
    .image-send {
        .add-image-btn {
            color: white;
            background: transparent;
            outline: none;
            border: none;
            padding-left: 0.6rem;
            cursor: pointer;
            img {
                width: 2rem;
                height: 2rem;
            }
        }
    }
`

export default ChatInput

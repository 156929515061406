import React, { useEffect, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import styled from "styled-components"
import { SubscriptionSlide } from "./SubscriptionSlide"
import axiosInstance from "../../config/api/axois"
import { Spinner } from "../Global/Spinner"
import {
    get_AllGeneralSubscriptionPlan,
    post_subscription,
} from "../../api/EndPoints"
import { notifyFailure } from "../../helpers/notifications/notifyFailure"
import { useDispatch, useSelector } from "react-redux"
import { notifyToast } from "../../helpers/notifications"
import backArrow from "../../static/images/general/back-arrow.svg"
import { showContactModal } from "../../store/actions/modalActions"
import { hideSubscriptionModal } from "../../store/actions/modalActions"

const url =
    process.env.REACT_APP_NODE_ENV === "development"
        ? process.env.REACT_APP_LOCAL_HOST
        : `${process.env.REACT_APP_PRODUCTION}`

const DiscalimerWrapper = styled.div`
    padding: 0.5rem 1rem 1rem 1rem;

    background-color: var(--custom-light-bg);
    border-radius: 1rem;
    margin-bottom: 20px;
    margin-top: 20px;
    .disclaimerInnerWrapper {
        width: 60%;
        margin: 0 auto;
        text-align: center;
    }
    p {
        font-size: 1rem;
    }
    .back-arrow {
        img {
            width: 50px;
            color: goldenrod;
        }
        cursor: pointer;
        color: goldenrod;
    }
    h1 {
        color: goldenrod;
        font-weight: 700;
    }
    h3 {
        color: goldenrod;
        font-weight: 700;
    }
    .p1 {
        font-weight: 600;
        text-decoration: underline;
        font-size: 1.2rem;
    }
    .p3 {
        span {
            font-weight: 900;
            cursor: pointer;
        }
        span:hover {
            text-decoration: underline;
        }
        a {
            font-weight: 900;
            cursor: pointer;
            color: black;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    .p6 {
        span {
            font-weight: 800;
        }
    }
`

const PlanWrapper = styled.div`
    .hover-underline-animation {
        display: inline-block;
        position: relative;
        color: black;
        font-size: large;
        font-weight: 800;
        cursor: pointer;
    }

    .hover-underline-animation::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: goldenrod;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    .hover-underline-animation:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    text-align: center;

    .disclaimer2 {
        display: flex;
        justify-content: center;
        padding: 1%;
        border-radius: 1.8rem;
        margin-bottom: 20px;
        width: 100%;
        background-color: var(--custom-light-bg);
        input {
            margin-right: 10px;
        }
    }
    .disclaimer {
        padding: 2%;

        background-color: var(--custom-light-bg);
        border-radius: 1.8rem;
        margin-bottom: 20px;
        margin-top: 20px;

        p {
            text-align: justify;
            margin: 0 auto;
            width: 60%;
            a {
                text-decoration: none;
                color: black;
                font-weight: 900;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        text-align: center;
        h2 {
        }
    }
    .CardGroupPlan {
        box-shadow: inset 0 0px 30px 10px var(--cardshadowlight) !important;
        padding-bottom: 3rem !important;

        @media (max-width: 500px) {
            padding: 0rem;
        }
    }
    .Header {
        margin: 0 auto;

        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1px;
        background-color: var(--custom-light-bg);
        display: inline;
        padding: 0.5rem 1rem;
        border-radius: 2.4rem;
        width: 100%;
    }
    .slick-slider,
    .noPlan {
        background-color: var(--custom-light-bg);
        padding: 3rem 3rem 0 3rem;
        border-radius: 20px;
    }
    .slick-slide ul li {
        margin-bottom: 1.5rem;
    }
    .slick-slide img {
        display: inline;
        margin-right: 1rem;
        width: 6px;
    }
    .slick-slide > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slick-list {
        padding-bottom: 4rem !important;
    }
    .planCard {
        color: var(--white) !important;
        box-shadow: inset 0 0 25px 3px var(--cardshadow);
        border-radius: 10px;
        border: 1px solid var(--custom-border-color);
        position: relative;
        width: 100%;
        max-width: 250px;
    }
    .planCardDetailListOuter {
        display: flex;
        justify-content: center;
    }
    .planCardDetailList {
        text-transform: capitalize;
        text-decoration: none;
        list-style: none;
        //margin-bottom: 2rem;
        padding-left: 0rem;
    }
    .planCardDetailList svg {
        margin-right: 0.5rem;
        fill: var(--yelowcolor);
    }
    .PlanBtn {
        position: absolute;
        bottom: -25px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }
    .CardHeading {
        margin: auto;
        color: var(--white) !important;
        margin: 1rem;
        letter-spacing: 0.5px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
            sans-serif;
    }
    .CardPrice {
        color: var(--white) !important;
        margin: auto;

        height: 104px;

        outline: 2.5px solid var(--custom-secondry-bg);
        border: 2px solid var(--custom-secondry-bg);
        box-shadow: 0 0 25px 3px var(--custom-primary-bg);
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .currencyPlan {
        font-size: 18px;
        color: var(--color);
    }
    .mainPrice {
        line-height: normal;
        font-size: 32px;
        color: var(--yelowcolor);
    }
    .monthPlan {
        color: var(--color);
        font-size: 16px;
    }
    .slick-prev:before,
    .slick-next:before {
        font-size: 45px;
        color: var(--custom-orange-color);
    }
    .slick-prev {
        left: 10px;
    }
    .slick-next {
        right: 35px;
    }
    .spinnercenter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .noFLex {
        display: block;
        text-align: center;
    }
`

const SubscriptionCards = () => {
    const [showSpinner, setShowSpinner] = useState(false)
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const { profile } = useSelector((state) => state.userProfile)

    const [subscriptionModels, setSubscriptionModels] = useState([])
    const [disabled, setDisabled] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        disabled &&
            notifyToast({
                content: "Please agree to the terms and conditions.",
                type: "info",
            })
    }, [disabled])

    let settings = {
        dots: true,
        infinite: false,
        // autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        arrows: true,
        initialSlide: 0,
        swipeToSlide: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    const handleSubscrition = (id) => {
        axiosInstance()
            .post(post_subscription(), {
                subscription_plan: id,
                success_url: `${url}/payment-success/success`,
                cancel_url: `${url}/payment-failed/failed`,
            })
            .then((response) => {
                setSubscriptionModels(response?.data?.results)
                window.location.href = response.data.payment_url
            })
            .catch((err) => {
                notifyFailure(err?.message || "error occured")
            })
    }
    const handleGetAllPlans = () => {
        setShowSpinner(true)
        axiosInstance()
            .get(get_AllGeneralSubscriptionPlan())
            .then((response) => {
                setShowSpinner(false)

                console.log(response?.data?.results)

                setSubscriptionModels(response?.data?.results)
            })
            .catch((err) => {
                notifyFailure(err?.message || "error occured")
            })
    }
    useEffect(() => {
        profile?.id && handleGetAllPlans()
    }, [profile])

    return (
        <div>
            {showDisclaimer ? (
                <DiscalimerWrapper>
                    <div className="back-arrow">
                        <img
                            src={backArrow}
                            alt="back arrow"
                            onClick={() => setShowDisclaimer(false)}
                        />
                    </div>
                    <div className="disclaimerInnerWrapper">
                        <h1>Disclaimer</h1>
                        <p className="p1">
                            {" "}
                            All membership subscriptions once paid and confirmed
                            are considered non-refundable.
                        </p>
                        <p className="p2">
                            {" "}
                            If a subscriber wishes to cancel a membership
                            subscription prior to the next automatic renewal, a
                            request for cancellation must be received at least
                            30 days prior to the renewal date.
                        </p>
                        <p className="p3">
                            {" "}
                            Send your request via the{" "}
                            <span
                                onClick={() => {
                                    dispatch(hideSubscriptionModal())
                                    dispatch(showContactModal())
                                }}
                            >
                                CONTACT US
                            </span>{" "}
                            page on the{" "}
                            <a href="https://www.esotericcuriosa.com">
                                Esoteric Curiosa website.
                            </a>
                        </p>
                        <p className="p4">
                            If the cancellation is received after the renewal
                            payment has been completed, the cancellation would
                            then be effective for the next renewal date
                            sequence.
                        </p>
                        <p className="p5">
                            We cannot pro-rate subscriptions and we do not
                            refund subscriptions that are canceled arbitrarily
                            during a subscription cycle.
                        </p>
                        <p className="p6">
                            {" "}
                            To complete your Subscription after having read the
                            Disclaimer please check below where it says -{" "}
                            <span> ‘I agree to the Terms and Conditions’</span>.
                            This will then successfully complete your
                            Subscription to The Esoteric Curiosa.
                        </p>
                        <h3>Now Get Ready To Be Amazed!</h3>{" "}
                    </div>
                </DiscalimerWrapper>
            ) : (
                <PlanWrapper>
                    <h2 className="Header">SUBSCRIPTION</h2>

                    <div className=" logincardgroup CardGroupPlan">
                        <div className="disclaimer">
                            <h2>Disclaimer</h2>
                            <p>
                                {" "}
                                All membership subscriptions once paid &
                                confirmed are considered non-refundable. If a
                                subscriber wishes to cancel a membership
                                subscription prior to the next automatic
                                renewal, a request of cancellation must be
                                received (30) days prior to the renewal day.
                                Send the request to{" "}
                                <svg
                                    viewBox="0 0 512 512"
                                    width="20"
                                    fill="#ffffff"
                                    title="envelope"
                                >
                                    <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
                                </svg>
                                <a href="mailto:  theesotericcuriosa@gmail.com">
                                    {" "}
                                    theesotericcuriosa@gmail.com
                                </a>
                                . If the cancellation is received after the
                                renewal payment has been paid, the cancellation
                                would then be effective for the next renewal
                                date sequence. Any disputed charge that is
                                placed through the subscriber's bank without
                                following the above guidelines will be contested
                                for failure to follow cancellation procedures.
                            </p>
                        </div>

                        {!showSpinner ? (
                            subscriptionModels.length !== 0 &&
                            !profile?.current_subscription_plan ? (
                                <div>
                                    <label className="disclaimer2">
                                        <input
                                            type="checkbox"
                                            defaultChecked={!disabled}
                                            onChange={() =>
                                                setDisabled(!disabled)
                                            }
                                        />

                                        <div>
                                            {" "}
                                            I agree to the{" "}
                                            <span
                                                className="hover-underline-animation"
                                                onClick={() =>
                                                    setShowDisclaimer(true)
                                                }
                                            >
                                                Terms and Conditions.
                                            </span>
                                        </div>
                                    </label>
                                    <Slider {...settings}>
                                        {!profile?.current_subscription_plan ? (
                                            subscriptionModels.map((model) => {
                                                if (model.is_public) {
                                                    return (
                                                        <SubscriptionSlide
                                                            id={model?.id}
                                                            title={
                                                                model?.plan_name
                                                            }
                                                            price={model?.price}
                                                            year={model?.per}
                                                            timeSpan={
                                                                model?.per_length
                                                            }
                                                            description={
                                                                model?.description
                                                            }
                                                            handleSubscrition={() =>
                                                                handleSubscrition(
                                                                    model?.id
                                                                )
                                                            }
                                                            is_public={
                                                                model.is_public
                                                            }
                                                            disabled={disabled}
                                                        />
                                                    )
                                                } else {
                                                    return <></>
                                                }
                                            })
                                        ) : (
                                            <div className="noFLex">
                                                <h3>
                                                    Your current subscription
                                                </h3>
                                                <br />

                                                <SubscriptionSlide
                                                    id={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.id
                                                    }
                                                    title={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.plan_name
                                                    }
                                                    price={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.price
                                                    }
                                                    year={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.per
                                                    }
                                                    timeSpan={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.per_length
                                                    }
                                                    description={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.description
                                                    }
                                                    is_public={
                                                        profile
                                                            ?.current_subscription_plan
                                                            ?.subscription_plan
                                                            ?.is_public
                                                    }
                                                    nosub={true}
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            ) : profile ? (
                                <div className="noPlan">
                                    <h2>
                                        We Don't Have Any Subscription Plans
                                        Yet!
                                    </h2>
                                </div>
                            ) : (
                                <div className="noPlan">
                                    <h2>
                                        Please Login to see subscription plans.
                                    </h2>
                                </div>
                            )
                        ) : (
                            <div>
                                <div className="spinnercenter">
                                    <Spinner size={3} />
                                </div>
                                {!profile && (
                                    <h2 style={{ color: "black" }}>
                                        Please Login to See subscriptoin plans.
                                    </h2>
                                )}
                            </div>
                        )}
                    </div>
                </PlanWrapper>
            )}
        </div>
    )
}

export default SubscriptionCards

import styled from "styled-components"
import prev from "../../static/images/Auth/2516190@0.png"
import next from "../../static/images/Auth/2516189@0.png"
import ReactHtmlParser from "react-html-parser"
import { BookLoader } from "../Global/BookLoader"
import moment from "moment/moment"
import AxiosInstance from "../../config/api/axois"
import { useDispatch, useSelector } from "react-redux"
import {
    FETCH_ALL_BLOGS_BY_LABEL_START,
    FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
    FETCH_ALL_BLOGS_BY_LABEL_FAILED,
} from "../../store/constants/blogsConstants"
import { get_AllBlogsByLabel } from "../../api/EndPoints"
import { showBlogModal } from "../../store/actions/modalActions"

const ListCaptions = styled.div`
    ul {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-end;
        list-style: none;
        li {
            .page-item {
                a.page-link {
                    color: #000;
                }
            }

            span.page-link.next,
            span.page-link.prev {
                border: 0px;
                transform: scale(1);
                margin: 0rem;
                cursor: pointer;
                &:hover {
                    background-color: transparent;
                    transform: scale(1.2);
                }
                &:focus {
                    background-color: transparent;
                    transform: scale(0.9);
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
            }
            span.disabled {
                cursor: not-allowed;
                pointer-events: none;
                img {
                    filter: sepia(73%) brightness(60%) hue-rotate(195deg)
                        saturate(1000%);
                    -webkit-filter: sepia(73%) brightness(60%)
                        hue-rotate(195deg) saturate(1000%);
                    -moz-filter: sepia(73%) brightness(60%) hue-rotate(195deg)
                        saturate(1000%);
                }
            }
            img {
                width: 45px;
                height: 30px;
                object-fit: cover;
            }
        }
    }
`
const Wrapper = styled.div`
    width: 96%;
    height: 80vh;
    margin: 0 auto;
    padding-bottom: 30px;
    overflow: auto;
    .blogContentOuter {
        html > body {
            background-color: white !important;
            background-image: none;
        }
        text-align: left;
        .dateTime {
            text-align: left;
            padding-top: 50px;
        }
    }
    h2 {
        font-size: 1.6rem;
    }
    img {
        /* height: 100%;
    object-fit: contain;
    margin: 1rem 0; */
    }
    .detail {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .blogTile {
        font-size: 1.6rem;
    }
    ::-webkit-scrollbar {
        background: var(--custom-input-border) !important;
        height: 4px !important;
        width: 4px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--custom-input-border) !important;
        height: 4px !important;
        width: 4px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--custom-orange-color) !important;
        height: 0px !important;
        width: 0px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--custom-orange-color) !important;
        height: 4px !important;
        width: 0px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
`
const BlogComp = ({
    blog,
    loadBlogInfo,
    loading,
    blogsInfo,
    paginationCurrentPage,
    currentLabel,
    setPaginationCurrentPage,
    setDontFetch,
    lastPage,
}) => {
    const currIndex = blogsInfo?.findIndex((x) => x.id === blog?.id)

    const dispatch = useDispatch()

    const goToNextPage = currIndex === blogsInfo?.length - 1
    const goToPrevPage = currIndex === 0

    const nextDisabled =
        paginationCurrentPage === lastPage &&
        currIndex === blogsInfo?.length - 1
    const prevDisabled = paginationCurrentPage === 1 && currIndex === 0

    console.log(paginationCurrentPage)

    const { search } = useSelector((state) => state.blogModal)

    const nextPage = async () => {
        if (goToNextPage) {
            setDontFetch(true)
            setPaginationCurrentPage(paginationCurrentPage + 1)
            dispatch({ type: FETCH_ALL_BLOGS_BY_LABEL_START })

            AxiosInstance()
                .get(
                    get_AllBlogsByLabel(
                        currentLabel,
                        (paginationCurrentPage + 1) * 10 - 10,
                        search
                    )
                )
                .then(({ data }) => {
                    setDontFetch(false)

                    dispatch({
                        type: FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
                        payload: {
                            AllBlogsData: data?.results,
                            count: data?.count,
                            currentLabel: currentLabel,
                        },
                    })

                    dispatch(showBlogModal(data?.results[0]?.id, currentLabel))
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_ALL_BLOGS_BY_LABEL_FAILED,
                        payload:
                            error.response && error.response.data.message
                                ? error.response.data.message
                                : error.message,
                    })
                })
        } else {
            if (currIndex < blogsInfo.length - 1) {
                const nextID = blogsInfo[currIndex + 1].id
                loadBlogInfo(nextID, currentLabel)
            }
        }
    }
    const prevPage = async () => {
        if (goToPrevPage) {
            setDontFetch(true)
            setPaginationCurrentPage(paginationCurrentPage - 1)
            dispatch({ type: FETCH_ALL_BLOGS_BY_LABEL_START })

            AxiosInstance()
                .get(
                    get_AllBlogsByLabel(
                        currentLabel,
                        (paginationCurrentPage - 1) * 10 - 10,
                        search
                    )
                )
                .then(({ data }) => {
                    setDontFetch(false)

                    dispatch({
                        type: FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
                        payload: {
                            AllBlogsData: data?.results,
                            count: data?.count,
                            currentLabel: currentLabel,
                        },
                    })

                    dispatch(showBlogModal(data?.results[9]?.id, currentLabel))
                })
                .catch((error) => {
                    dispatch({
                        type: FETCH_ALL_BLOGS_BY_LABEL_FAILED,
                        payload:
                            error.response && error.response.data.message
                                ? error.response.data.message
                                : error.message,
                    })
                })
        } else {
            if (currIndex > 0) {
                const prevID = blogsInfo[currIndex - 1].id
                loadBlogInfo(prevID, currentLabel)
            }
        }
    }

    return (
        <div className="pageContentOuter">
            <ListCaptions>
                <ul className="captions">
                    <li className="page-item">
                        <span
                            className={`page-link prev ${
                                prevDisabled ? "disabled" : ""
                            }`}
                            onClick={prevPage}
                        >
                            <img src={prev} alt="prev" />
                        </span>
                    </li>
                    <li className="page-item">
                        <span
                            className={`page-link next ${
                                nextDisabled ? "disabled" : ""
                            }`}
                            onClick={nextPage}
                        >
                            <img src={next} alt="next" />
                        </span>
                    </li>
                </ul>
            </ListCaptions>
            {loading === true ? (
                <BookLoader />
            ) : (
                <Wrapper>
                    <span className="blogTile">{blog?.title}</span>
                    <div className="blogContentOuter">
                        {ReactHtmlParser(blog?.content)}
                        <span className="dateTime">
                            {" "}
                            {moment(blog?.date_created)
                                .utc()
                                .format("dddd, MMMM Do YYYY, h:mm:ss a")}{" "}
                        </span>
                    </div>
                </Wrapper>
            )}
        </div>
    )
}

export default BlogComp

  
  
import { useState, useEffect } from 'react';

export function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const resizeHanlder = () => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHanlder);

    return () => {
      window.removeEventListener('resize', resizeHanlder);
    }
  }, []);

  return size;
}
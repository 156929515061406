import { get_AllChatMessage, post_ChatMessage } from "../../api/EndPoints"
import {
    FETCH_MESSAGES_START,
    FETCH_MESSAGES_SUCCESS,
    FETCH_MESSAGES_FAILURE,
    SEND_MESSAGE_START,
    SEND_MESSAGE_SUCCESS,
    ADD_MESSAGE,
    SEND_MESSAGE_FAILURE,
} from "../constants/chatConstant"

import axiosInstance from "../../config/api/axois"
import { errorPayload } from "../helper/errorPayload"

export const fetchMessages = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_MESSAGES_START })
        const { data } = await axiosInstance().get(get_AllChatMessage())
        dispatch({
            type: FETCH_MESSAGES_SUCCESS,
            payload: data?.results,
        })
    } catch (error) {
        dispatch({
            type: FETCH_MESSAGES_FAILURE,
            //payload: errorPayload(error),
        })
    }
}

export const sendMessage = (receiverId, message) => async (dispatch) => {
    try {
        dispatch({ type: SEND_MESSAGE_START })
        const { data } = await axiosInstance().post(post_ChatMessage(), {
            receiver: receiverId,
            text: message,
        })

        dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SEND_MESSAGE_FAILURE,
            //payload: errorPayload(error),
        })
    }
}

export const addMessage = (message) => async (dispatch) => {
    dispatch({ type: ADD_MESSAGE, payload: message })
}

import React from "react"
import CountdownTimer from "react-component-countdown-timer"
import styled from "styled-components"
import { useSelector } from "react-redux"

const Wrapper = styled.div`
    .displayedTime {
        display: flex;
        justify-content: center;
        .countBox {
            display: flex;
            flex-direction: row-reverse;
            gap: 1rem;
            @media (max-width: 400px) {
                gap: 0.2rem;
            }
            .countBoxItem {
                display: flex;
                flex-direction: column;
                gap: 0.4rem;
                .label {
                    font-size: 1rem !important;
                }
                .count {
                    /* border:1px solid #000; */
                    border-radius: 4px;
                    font-size: 1.6rem;
                    padding: 0.3rem;
                    background-color: var(--custom-orange-color) !important;
                    color: #fff !important;
                }
            }
            .split {
                font-size: 4rem;
                color: var(--custom-orange-color) !important;
                font-family: "poppins" !important ;
                -webkit-font-smoothing: antialiased;
            }
        }
    }
`

export const TimerCounter = () => {
    const userInfo = useSelector((state) => state.userProfile)
    const { profile } = userInfo

    const dateOfSubscription = new Date(
        profile?.current_subscription_plan?.date_added
    )

    const noOfMonths =
        profile?.current_subscription_plan?.subscription_plan?.per === "Monthly"
            ? profile?.current_subscription_plan?.subscription_plan?.per_length
            : profile?.current_subscription_plan?.subscription_plan
                  ?.per_length * 12

    const endingSubscriptionDate = new Date(
        dateOfSubscription.setMonth(dateOfSubscription.getMonth() + noOfMonths)
    )
    const endingSubscriptionTime = endingSubscriptionDate.getTime()

    const dateNow = Date.now()

    const secondsRemaining = (endingSubscriptionTime - dateNow) / 1000

    const endFunction = () => {
        // dispatch(logou)
    }
    return (
        <Wrapper>
            <CountdownTimer
                count={secondsRemaining}
                onEnd={endFunction()}
                showTitle
            />
        </Wrapper>
    )
}

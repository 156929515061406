import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { fetchSingleNotification } from "../../store/actions/notificationAction"
import { Spinner } from "../Global/Spinner"
import ReactHtmlParser from "react-html-parser"

const Wrapper = styled.div`
  border: 1px solid var(--custom-border-color);
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  .singleNotifyData {
    padding: 1rem;
    border-radius: 10px;
    background-color: aliceblue;
  }
`
export const NotificationSinglePage = ({ id }) => {
  const dispatch = useDispatch()
  const NotificationData = useSelector((state) => state.fetchSingleNotification)
  const { loading, singleNotificationData: data } = NotificationData

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleNotification(id))
    }
  }, [id, dispatch])
  return (
    <div className='pageContentOuter'>
      <Wrapper>
        {loading ? (
          <Spinner />
        ) : (
          <div className='singleNotifyData'>
            <h1>{data?.title}</h1>
            <p>{ReactHtmlParser(data?.content)}</p>
          </div>
        )}
      </Wrapper>
    </div>
  )
}

import { useState } from "react"
import { notifyFailure } from "../../../helpers/notifications/notifyFailure"
import styled from "styled-components"
import { InputComponent } from "../components/InputELement"
import { FormComponent } from "../components/FormElement"
import { HandleOnChangeInput } from "../../../helpers/formInput/HandleOnChangeInput"
import { SizedBox } from "../../Global/SizedBox"
import { AuthButtonContainer } from "../components/AuthButtonContainer"
import { Button } from "../../Global/Button"
import { useDispatch, useSelector } from "react-redux"
import { forgotPassword } from "../../../store/actions/userActions"
import { Spinner } from "../../Global/Spinner"

const Wrapper = styled.div`
    width: 100%;
    min-width: 400px;
    @media (max-width: 600px) {
        min-width: 100%;
    }
    h2 {
        font-size: 2rem;
        font-weight: 400;
        @media (max-width: 600px) {
            font-size: 1.4rem;
        }
    }
`
export const ForgotPasswordForm = ({ showLogin, ShowResetPassword }) => {
    const [data, setData] = useState({})
    const dispatch = useDispatch()
    const Info = useSelector((state) => state.resetPass)
    let { error, loading } = Info

    // notifying if error from reducer state
    error && notifyFailure(error)

    const uri =
        process.env.REACT_APP_NODE_ENV === "production"
            ? `${process.env.REACT_APP_PRODUCTION}:3000`
            : process.env.REACT_APP_LOCAL_HOST

    const handleInitiateForgotPassword = async (tempData) => {
        dispatch(forgotPassword(tempData))
    }

    const handleGoToLogin = (e) => {
        e.preventDefault()
        showLogin()
    }

    const handleGoToResetPass = (e) => {
        e.preventDefault()
        ShowResetPassword()
    }

    const handleSendVarification = (e) => {
        e.preventDefault()
        if (!data.email) {
            notifyFailure(`Email is required.`)
        } else {
            if (data.email) {
                let tempData = {
                    ...data,
                    page_uri: `${uri}/auth/finalize_reset_password_email`,
                }

                // handleSendVarificationEmail(tempData)
                handleInitiateForgotPassword(tempData)
            }
        }
    }
    return (
        <Wrapper>
            <FormComponent>
                <label htmlFor="forgot">Enter Your Email</label>
                <InputComponent
                    id="forgot"
                    type="email"
                    value={data?.email}
                    onChange={(e) => {
                        HandleOnChangeInput(e, "email", setData, data)
                    }}
                />

                <SizedBox height={1.5} />
                <AuthButtonContainer>
                    {!loading ? (
                        <div className="formfooter">
                            <Button
                                textTransform={"uppercase"}
                                fontSize={16}
                                addEffect={true}
                                paddingLeftRight={3}
                                height={35}
                                BgColor={"orange-color"}
                                border={"border-color"}
                                onClick={(e) => handleSendVarification(e)}
                            >
                                SEND VERFICATION LINK
                            </Button>
                            <SizedBox height={3.0} />
                            <p>
                                Do You Want To Go Back?
                                <strong onClick={handleGoToLogin}>
                                    &nbsp;Go To Login
                                </strong>
                            </p>
                            <p>
                                Do You Want To Go Back?
                                <strong onClick={(e) => handleGoToResetPass(e)}>
                                    &nbsp;Go To Reset
                                </strong>
                            </p>

                            <SizedBox height={3.0} />
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </AuthButtonContainer>
            </FormComponent>
        </Wrapper>
    )
}

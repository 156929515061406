import { Route, Routes, useNavigate } from "react-router-dom"
import LogoutContainer from "../components/Authentication/logout/Container"
import HomeScreen from "../screens/HomeScreen"
import { Button } from "../components/Global/Button"
import styled from "styled-components"

const NotfoundWrapper = styled.div`
    background-color: antiquewhite;
    position: absolute;
    width: 500px;
    height: 300px;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;

    div {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
            margin-bottom: 20px;
        }
    }
`

export const GlobalRoutes = () => {
    return (
        <Routes>
            <Route path="/" exact={true} element={<HomeScreen />} />
            <Route path="/logout" exact={true} element={<LogoutContainer />} />
            <Route
                path="/payment-success/:state"
                exact={true}
                element={<HomeScreen />}
            />
            <Route
                path="/auth/finalize_verify_email"
                exact={true}
                element={<HomeScreen />}
            />
            <Route
                path="/payment-failed/:state"
                exact={true}
                element={<HomeScreen />}
            />
            <Route
                path="/auth/finalize_reset_password_email"
                exact={true}
                element={<HomeScreen />}
            />
            <Route path="*" exact={true} element={<Notfound />} />
        </Routes>
    )
}

const Notfound = () => {
    const navigate = useNavigate()
    return (
        <NotfoundWrapper>
            <div>
                {" "}
                <span> Sorry, this page does'nt exist.</span>{" "}
                <Button
                    maxWidth={150}
                    height={40}
                    fontSize={13}
                    textTransform={"uppercase"}
                    onClick={() => navigate("/")}
                >
                    Go to Home
                </Button>
            </div>
        </NotfoundWrapper>
    )
}

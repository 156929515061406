import { useSelector } from "react-redux"
import { hideAdminChatModal } from "../../store/actions/modalActions"
import { ModalComponent } from "../Global/Modal"
import { CloseBtn } from "../Global/CloseBtn"
import { useDispatch } from "react-redux"
import Chat from "./pages/Chat"

import { useEffect } from "react"
export default function AdminChatModal() {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(hideAdminChatModal())
  }

  const spinner = document.getElementById("spinner")

  useEffect(() => {
    spinner.style.display = "none"
  })

  const modalInfo = useSelector((state) => state.adminChatModal)
  const { show } = modalInfo

  return (
    <ModalComponent
      isOpen={show}
      onRequestClose={closeModal}
      modalLabel={"AdminChat Modal"}
      classNameFromProps='admin_modal'
    >
      <>
        <Chat />
        <CloseBtn handleOnClickClose={closeModal} />
      </>
    </ModalComponent>
  )
}

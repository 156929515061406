import React from "react"
import { hidePodcastModal } from "../../store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { ModalComponent } from "../Global/Modal"
import { CloseBtn } from "../Global/CloseBtn"
import { PodcastPage } from "../podcast/PodcastPage"

const PodcastModal = () => {
    const dispatch = useDispatch()
    const modalInfo = useSelector((state) => state.PodcastModal)
    const { show, podcastgetId } = modalInfo
    function closeModal() {
        dispatch(hidePodcastModal())
    }

    return (
        <ModalComponent
            isOpen={show}
            onRequestClose={closeModal}
            modalLabel={"Blog Modal"}
            classNameFromProps="blog_modal"
        >
            <>
                <PodcastPage id={podcastgetId} />
                <CloseBtn handleOnClickClose={closeModal} />
            </>
        </ModalComponent>
    )
}

export default PodcastModal

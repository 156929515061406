import React, { useState, useRef } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import moment from "moment/moment"
import noti1 from "../../../../static/images/icons/noti1.png"
import { Button } from "../../../Global/Button"
import { fetchPosition } from "../../../../store/actions/contentActions"

import {
    showPdfModal,
    showBlogModal,
    showPodcastModal,
    showNotificationModal,
    showSubscriptionModal,
    showEmailModal,
    showLoginModal,
} from "../../../../store/actions/modalActions"

import Pagination from "../../../Global/pagination/Pagination"
import { notifyInfo } from "../../../../helpers/notifications/notifyInfo"

import { fetchSinglePDF } from "../../../../store/actions/pdfActions"

const ItemWrapper = styled.div`
    display: flex;
    border-radius: 7px;
    margin-bottom: 5px;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    background-color: ${(props) => props.color};
    margin-left: 7px;
    .dateTime {
        font-size: 0.6rem;
    }

    .item_description {
        font-size: 0.7rem;
    }
`

const NotifyWrapper = styled.div`
    height: 90%;
    overflow-y: scroll;
    text-align: left;

    .text-center {
        text-align: center;
    }

    .mb-3 {
        margin-bottom: 10px;
    }

    item_wrapper.scroll {
        margin: 4px, 4px;
        padding: 4px;
        background-color: green;
        width: 500px;
        height: 110px;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: justify;
    }

    .item_text {
        margin-left: 10px;
    }
    ::-webkit-scrollbar {
        background: var(--custom-input-border) !important;
        height: 4px !important;
        width: 4px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--custom-input-border) !important;
        height: 4px !important;
        width: 4px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--custom-orange-color) !important;
        height: 0px !important;
        width: 0px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--custom-orange-color) !important;
        height: 4px !important;
        width: 0px !important;
        margin: 0 !important;
        padding: 0 !important;
        border-radius: 5px !important;
    }
`

const Wrapper = styled.div`
    .flip_card {
        background-color: transparent;
        width: 150px;
        height: 140px;
        perspective: 1000px;
        position: relative;
        z-index: 9 !important;
        cursor: pointer;
        .flip_card_inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            .flip_card_front {
                background-color: #bbb;
                color: black;
                width: 150px;
                height: 140px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    padding: 0.01rem;
                    border: 1px solid var(--custom-orange-color);
                }
            }
            .flip_card_back {
                background-color: var(--custom-orange-color);
                color: white;
                padding: 1rem 0;
                transform: rotateY(180deg);
                h4 {
                    font-size: 1rem;
                }
                p {
                    font-size: 0.85rem;
                }
            }
        }
    }
    .flip_card:hover .flip_card_inner {
        transform: rotateY(180deg);
    }
    .flip_card_front,
    .flip_card_back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
`
const Records = (props) => {
    const [recordsPerPage] = useState(10)
    const dispatch = useDispatch()

    const { profile } = useSelector((state) => state.userProfile)

    const openPdfModal = (id) => {
        dispatch(showPdfModal(id))
        dispatch(fetchSinglePDF(id))
    }
    const openBlogModal = (id, labelgetId) => {
        dispatch(showBlogModal(id, labelgetId))
    }
    const openPodcastModal = (id) => {
        dispatch(showPodcastModal(id))
    }
    const openNotificationModal = (id) => {
        dispatch(showNotificationModal(id))
    }

    function getWordStr(str) {
        return str?.split(/\s+/).slice(0, 4).join(" ")
    }

    const showNextNotifications = () => {
        props.setOffset(props.offset + 1)

        console.log(props)
    }

    const showPreviousNotifications = () => {
        props.setOffset(props.offset - 1)
    }
    const blogActions = (item) => {
        openBlogModal(item?.id, item?.label?.id)
        dispatch(fetchPosition(item?.id))
    }

    const handleOnOpen = (item) => {
        if (profile?.id) {
            if (profile?.is_email_verified) {
                if (profile?.current_subscription_plan) {
                    props.type === "pdf_list"
                        ? openPdfModal(item?.id)
                        : props.type === "blog_list"
                        ? blogActions(item)
                        : props.type === "notification"
                        ? openNotificationModal(item?.id)
                        : props.type === "podcast" && openPodcastModal(item?.id)
                } else {
                    notifyInfo("Please subscribe to continue.")
                    dispatch(showSubscriptionModal())
                }
            } else {
                notifyInfo("Please verify your email to continue.")
                dispatch(showEmailModal())
            }
        } else {
            dispatch(showLoginModal())
            notifyInfo("Please login first.")
        }
    }
    const dummy = useRef(null)

    return props.type === "notification" ? (
        <>
            <NotifyWrapper>
                <div className="text-center mb-3">
                    {props.previous && (
                        <Button
                            textTransform={"uppercase"}
                            fontSize={9}
                            maxWidth={200}
                            border={"transparent"}
                            height={31}
                            onClick={showPreviousNotifications}
                        >
                            Show previous notifications
                        </Button>
                    )}
                </div>
                {props?.data?.length === 0
                    ? "No content available for now ..."
                    : props?.data?.map((item, i) => (
                          <ItemWrapper
                              color={
                                  i % 3 === 0
                                      ? "#F1E5AC"
                                      : i % 3 === 1
                                      ? "#dfd"
                                      : i % 3 === 2 && "#C0C0C0"
                              }
                              onClick={() => handleOnOpen(item)}
                          >
                              <div className="noti_logo">
                                  <img src={noti1} alt="notiq" />
                              </div>
                              <div
                                  className="
        item_text
        "
                              >
                                  <div className="div">{item?.title}</div>
                                  <div className="item_description">
                                      Please click to show the notification
                                      content.{" "}
                                  </div>
                                  <p className="dateTime">
                                      {" "}
                                      {moment(item?.date_added)
                                          .utc()
                                          .format(
                                              "MMMM Do YYYY, h:mm:ss a"
                                          )}{" "}
                                  </p>
                              </div>
                          </ItemWrapper>
                      ))}
                <div className="text-center">
                    {props.next && (
                        <Button
                            textTransform={"uppercase"}
                            fontSize={10}
                            maxWidth={200}
                            border={"transparent"}
                            height={31}
                            onClick={showNextNotifications}
                        >
                            Show more notifications
                        </Button>
                    )}
                </div>

                <div className="sss" ref={dummy}></div>
            </NotifyWrapper>
        </>
    ) : (
        <div className="pageContentOuter">
            {props?.data?.length === 0
                ? "No content available for now ..."
                : props?.data?.map((item, i) => {
                      return item?.hasOwnProperty("publish") &&
                          item?.publish ? (
                          <Wrapper key={i}>
                              <div
                                  className="flip_card"
                                  onClick={() => handleOnOpen(item)}
                              >
                                  <div className="flip_card_inner">
                                      <div className="flip_card_front">
                                          <img
                                              src={
                                                  props.type === "podcast"
                                                      ? "https://esotericcuriousa.s3.amazonaws.com/static/global/image/podcast.jpeg"
                                                      : item?.thumbnail?.file
                                              }
                                              alt=" down"
                                          />
                                      </div>
                                      <div className="flip_card_back">
                                          {getWordStr(
                                              item?.title
                                                  ? item?.title
                                                  : item?.name
                                          )}{" "}
                                          ...
                                          <p>
                                              {" "}
                                              {moment(item?.date_created)
                                                  .utc()
                                                  .format(
                                                      "dddd, MMMM Do YYYY, h:mm:ss a"
                                                  )}{" "}
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </Wrapper>
                      ) : (
                          !item?.hasOwnProperty("publish") && (
                              <Wrapper key={i}>
                                  <div
                                      className="flip_card"
                                      onClick={() => handleOnOpen(item)}
                                  >
                                      <div className="flip_card_inner">
                                          <div className="flip_card_front">
                                              <img
                                                  src={
                                                      props.type === "podcast"
                                                          ? "https://esotericcuriousa.s3.amazonaws.com/static/global/image/podcast.jpeg"
                                                          : item?.thumbnail
                                                                ?.file
                                                  }
                                                  alt=" down"
                                              />
                                          </div>
                                          <div className="flip_card_back">
                                              {getWordStr(
                                                  item?.title
                                                      ? item?.title
                                                      : item?.name
                                              )}{" "}
                                              ...
                                              <p>
                                                  {" "}
                                                  {moment(item?.date_created)
                                                      .utc()
                                                      .format(
                                                          "dddd, MMMM Do YYYY, h:mm:ss a"
                                                      )}{" "}
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              </Wrapper>
                          )
                      )
                  })}

            {props?.count !== 0 && (
                <Pagination
                    className="pagination-bar"
                    currentPage={props?.paginationCurrentPage}
                    totalCount={props?.count}
                    pageSize={recordsPerPage && recordsPerPage}
                    onPageChange={props?.setPaginationCurrentPage}
                />
            )}
        </div>
    )
}

export default Records

import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { fetchLabels } from "../../store/actions/blogsActions"
import styled from "styled-components"
import { Spinner } from "../Global/Spinner"

const Wrapper = styled.div`
    background-color: var(--custom-light-bg);
    border-radius: 10px;
    width: 400px;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .Scroll {
        max-height: 40em; /* 1.5 x 3 */
        overflow-y: auto;
    }
    h3 {
        margin-top: 20px;
    }
`

const Labels = ({ blogListId, handleGetBlogLabelList }) => {
    const dispatch = useDispatch()

    const { loading, AllLabels } = useSelector((state) => state.AllLabels)
    useEffect(() => {
        !AllLabels && dispatch(fetchLabels())
    }, [dispatch, AllLabels])

    return (
        <Wrapper>
            <h3>Labels</h3>
            {loading ? (
                <Spinner />
            ) : (
                <ul className="Scroll">
                    {AllLabels?.map((item, i) => (
                        <li
                            key={i}
                            className={`${
                                blogListId === item.id ? "active" : ""
                            }`}
                        >
                            <Link
                                to=""
                                onClick={() => handleGetBlogLabelList(item?.id)}
                            >
                                {item.name} &nbsp;
                                <span className="badge">
                                    {item?.no_of_blogs}
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </Wrapper>
    )
}

export default Labels

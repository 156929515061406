import React, { useEffect } from "react"
import { hideBlogModal } from "../../store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { ModalComponent } from "../Global/Modal"
import { CloseBtn } from "../Global/CloseBtn"
import { BlogsScreen } from "../../screens/BlogsScreen"
import {
    fetchAllBlogsByLabel,
    fetchSingleBlog,
} from "../../store/actions/blogsActions"

const BlogModal = () => {
    const dispatch = useDispatch()
    const modalInfo = useSelector((state) => state.blogModal)
    const { show, blogId, labelgetId } = modalInfo

    function closeModal() {
        dispatch(hideBlogModal())
    }
    useEffect(() => {
        if (blogId && labelgetId) {
            dispatch(fetchSingleBlog(blogId))
        }
    }, [blogId, labelgetId, dispatch])

    useEffect(() => {
        dispatch(fetchAllBlogsByLabel((labelgetId && labelgetId) || null))
    }, [labelgetId, dispatch])
    return (
        <ModalComponent
            isOpen={show}
            onRequestClose={closeModal}
            modalLabel={"Blog Modal"}
            classNameFromProps="blog_modal"
        >
            <>
                <BlogsScreen />
                <CloseBtn handleOnClickClose={closeModal} />
            </>
        </ModalComponent>
    )
}

export default BlogModal

import React, { useState } from "react"
import HTMLFlipBook from "react-pageflip"
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf"
import { ModalComponent } from "../Global/Modal"
import { useSelector, useDispatch } from "react-redux"
import { hidePdfModal } from "../../store/actions/modalActions"
import { CloseBtn } from "../Global/CloseBtn"
import styled from "styled-components"

const customStyles = {
    content: {
        top: "9%",
        left: "10%",
        right: "10%",
        bottom: "9%",
    },
}

const Wrapper = styled.div``

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const width = Math.abs(window.innerWidth - window.innerWidth * 0.57)
const height = Math.abs(window.innerHeight * 1.19)

const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage
                pageNumber={pageNumber}
                width={window.innerWidth < 650 ? width * 1.5 : width / 1.3}
            />
        </div>
    )
})

const PdfModal = () => {
    const dispatch = useDispatch()
    const [numPages, setNumPages] = useState("")
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
    }
    const pdfContent = useSelector((state) => state.singlePdf)
    const { pdf } = pdfContent

    const pdfModal = useSelector((state) => state.pdfModal)
    const { show } = pdfModal

    function closeModal() {
        dispatch(hidePdfModal())
    }

    return (
        <Wrapper>
            <ModalComponent
                isOpen={show}
                onRequestClose={closeModal}
                modalLabel={"Example Modal"}
                stylesFromProps={customStyles}
                classNameFromProps={"pdf"}
            >
                <div className="book">
                    <div className="outerWrapper">
                        <div className="innerWrapper">
                            <Document
                                file={pdf?.content?.file}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <HTMLFlipBook
                                    width={width}
                                    height={height}
                                    size="stretch"
                                    minWidth={315}
                                    maxWidth={800}
                                    minHeight={400}
                                    maxHeight={1000}
                                    flippingTime={1500}
                                    maxShadowOpacity={0.5}
                                    drawShadow={true}
                                    mobileScrollSupport={true}
                                    disableFlipByClick={true}
                                >
                                    {Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <Page pageNumber={index + 1} />
                                        )
                                    )}
                                </HTMLFlipBook>
                            </Document>
                        </div>
                    </div>
                </div>
                <CloseBtn handleOnClickClose={closeModal} />
            </ModalComponent>
        </Wrapper>
    )
}

export default PdfModal

export const SHOW_PDF_MODAL = "SHOW_PDF_MODAL"
export const HIDE_PDF_MODAL = "HIDE_PDF_MODAL"
export const FAILED_MODAL = "FAILED_PDF_MODAL"

export const SHOW_BLOG_MODAL = "SHOW_BLOG_MODAL"
export const HIDE_BLOG_MODAL = "HIDE_BLOG_MODAL"

export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL"
export const HIDE_LOGIN_MODAL = "HIDE_LOGIN_MODAL"

export const SHOW_PROFILE_MODAL = "SHOW_PROFILE_MODAL"
export const HIDE_PROFILE_MODAL = "HIDE_PROFILE_MODAL"

export const CONTACT_US_FORM_START = "CONTACT_US_FORM_START"
export const CONTACT_US_FORM_SUCCESS = "CONTACT_US_FORM_SUCCESS"
export const CONTACT_US_FORM_FAIL = "CONTACT_US_FORM_FAIL"

export const SHOW_SUBSCRIPTION_MODAL = "SHOW_SUBSCRIPTION_MODAL"
export const HIDE_SUBSCRIPTION_MODAL = "HIDE_SUBSCRIPTION_MODAL"

export const SHOW_PODCAST_MODAL = "SHOW_PODCAST_MODAL"
export const HIDE_PODCAST_MODAL = "HIDE_PODCAST_MODAL"

export const SHOW_NOTIFICATION_MODAL = "SHOW_NOTIFICATION_MODAL"
export const HIDE_NOTIFICATION_MODAL = "HIDE_NOTIFICATION_MODAL"

export const SHOW_CHAT_MODAL = "SHOW_CHAT_MODAL"
export const HIDE_CHAT_MODAL = "HIDE_CHAT_MODAL"

export const SHOW_ADMIN_CHAT_MODAL = "SHOW_ADMIN_CHAT_MODAL"
export const HIDE_ADMIN_CHAT_MODAL = "HIDE_ADMIN_CHAT_MODAL"

export const SHOW_CONTACTUS_MODAL = "SHOW_CONTACTUS_MODAL"
export const HIDE_CONTACTUS_MODAL = "HIDE_CONTACTUS_MODAL"

export const SHOW_EMAIL_MODAL = "SHOW_EMAIL_MODAL"
export const HIDE_EMAIL_MODAL = "HIDE_EMAIL_MODAL"

export const SHOW_RESET_PASSWORD_MODAL = "SHOW_RESET_PASSWORD_MODAL"
export const HIDE_RESET_PASSWORD_MODAL = "HIDE_RESET_PASSWORD_MODAL"

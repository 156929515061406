import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ModalComponent } from "../Global/Modal"
import {
    showEmailModal,
    hideProfileModal,
} from "../../store/actions/modalActions"
import { CloseBtn } from "../Global/CloseBtn"
import styled from "styled-components"
import { Button } from "../Global/Button"
import { EditProfile } from "../userprofile/ProfileForm"
import { RenewPassword } from "../userprofile/RenewPassword"

import { ProfileSubscription } from "../userprofile/ProfileSubscription"

const Wrapper = styled.div`
    width: 80%;
    height: 80vh;
    @media (max-width: 700px) {
        width: 100%;
    }
    .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1.5rem;
        background-color: transparent;
        padding: 10px;
        @media (max-width: 1100px) {
            grid-template-columns: auto;
        }
    }

    .grid-container > div {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        text-align: center;
        padding: 20px;
        font-size: 30px;
    }

    .Update_profile_outer {
        grid-row: 1 / 3;
    }

    .emailSection {
        display: flex;
        text-align: center;
        padding: 10% 0;
        width: 70%;
        margin: 0 auto;

        p {
            margin-top: 10px;
            margin-right: 10px;
        }
    }
`

const ProfileModal = () => {
    const dispatch = useDispatch()

    const modalInfo = useSelector((state) => state.profileModal)
    const { show } = modalInfo

    const userInfo = useSelector((state) => state.userProfile)
    const { profile } = userInfo

    function closeModal() {
        dispatch(hideProfileModal())
    }

    const openEmailVerify = () => {
        dispatch(hideProfileModal())

        dispatch(showEmailModal())
    }

    return (
        <ModalComponent
            isOpen={show}
            onRequestClose={closeModal}
            modalLabel={"Example Modal"}
            classNameFromProps="ProfileModal"
        >
            <Wrapper>
                <div className="grid-container">
                    {!profile?.is_email_verified && (
                        <div className="email_verification ">
                            <div className="emailSection">
                                <p>Email Verification</p>
                                <Button
                                    textTransform={"uppercase"}
                                    fontSize={16}
                                    maxWidth={200}
                                    border={"transparent"}
                                    height={41}
                                    onClick={openEmailVerify}
                                >
                                    Verify email
                                </Button>
                            </div>{" "}
                        </div>
                    )}

                    <div className="Update_profile_outer ">
                        <EditProfile />
                    </div>
                    {profile?.current_subscription_plan && (
                        <div className="View_Subscription ">
                            <ProfileSubscription />
                        </div>
                    )}

                    <div className="Update_Password ">
                        <RenewPassword />
                    </div>
                </div>

                <CloseBtn handleOnClickClose={closeModal} />
            </Wrapper>
        </ModalComponent>
    )
}

export default ProfileModal

import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import ChatInput from "./ChatInput"
import { v4 as uuidv4 } from "uuid"
import { useSelector } from "react-redux"
import defaultAvatar from "../../../static/images/general/DefaultAvatar.png"
import { sendMessage, addMessage } from "../../../store/actions/chatAction"
import { useDispatch } from "react-redux"

const Container = styled.div`
    display: grid;
    grid-template-rows: 10% 80% 10%;
    /* gap: 0.1rem; */
    overflow: hidden;

    .chat-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        border-bottom: 1px solid #ffffff15;
        -webkit-box-shadow: 0px 17px 20px -26px rgba(66, 68, 90, 1);
        -moz-box-shadow: 0px 17px 20px -26px rgba(66, 68, 90, 1);
        box-shadow: 0px 17px 20px -26px rgba(66, 68, 90, 1);
        .user-details {
            display: flex;
            align-items: center;
            gap: 1rem;
            img {
                width: 60px;
                height: 60px;
                object-fit: cover;
                padding: 0.3rem;
                border-radius: 50%;
            }
            .username {
                h2 {
                    color: #d3d3d3;
                }
            }
        }
    }

    .loading-messages {
        text-align: center;
        margin-top: 35vh;
        img {
            width: 120px;
            height: 120px;
        }
    }

    .chat-messages {
        padding: 1rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow: auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            &-thumb {
                background-color: #ffffff;
                width: 0.1rem;
                border-radius: 1rem;
            }
        }
        .message {
            display: flex;
            align-items: center;
            .content {
                max-width: 70px;
                overflow-wrap: break-word;
                padding: 1rem 1rem 0rem 1rem;
                font-size: 0.9rem;
                border-radius: 0.5rem;
                color: white;
                @media screen and (min-width: 720px) {
                    max-width: 50%;
                }
            }
            .content-image {
                max-width: 70%;
                /* justify-self: flex-end; */
                img {
                    max-width: 300px;
                }
            }
        }
        .sended {
            justify-content: flex-end;
            .content {
                background-color: #d3d3d3;
            }
        }
        .recieved {
            justify-content: flex-start;
            .content {
                background-color: #d3d3d3;
            }
        }
    }
    @media screen and (max-width: 900px) and (orientation: landscape) {
        grid-template-rows: 15% 70% 15%;

        .chat-header {
            .user-details {
                .avatar {
                    img {
                        height: 2.6rem;
                    }
                }
            }
        }
    }
`

const ChatContainer = ({ currentChat, currentUser }) => {
    const [socket, setSocket] = useState(null)
    const dispatch = useDispatch()
    const chats = useSelector((state) => state.messages)
    const { chats: allUsersMsgs } = chats

    const initSocket = useCallback(
        (socket_) => {
            socket_.onopen = function (e) {
                setSocket(socket_)
            }

            socket_.onclose = function (e) {
                setSocket(null)
            }

            socket_.onmessage = function (e) {
                const { data } = JSON.parse(e.data)
                dispatch(addMessage(data))
            }
        },
        [dispatch]
    )

    useEffect(() => {
        if (!socket) {
            initSocket(
                new WebSocket(
                    `${process.env.REACT_APP_MAIN_SERVER_URL_SOCKET}/ws/chat` +
                        "?token=" +
                        window.localStorage.getItem("access_token")
                )
            )
        }
    }, [initSocket, socket])

    const userProfile = useSelector((state) => state.userProfile)
    const { profile } = userProfile
    let messages = allUsersMsgs?.map((item) => {
        if (
            item.receiver === currentChat._id ||
            item.owner === currentChat._id
        ) {
            return {
                id: item.id,
                fromSelf: profile?.id === item.owner ? true : false,
                image: "",
                message: item.text,
            }
        }
    })

    const errorImageHandler = (e) => {
        e.target.src = defaultAvatar
    }

    messages.sort(function (a, b) {
        return a.id - b.id
    })

    const handleSendMessage = async (msg) => {
        if (currentUser) {
            dispatch(sendMessage(currentChat._id, msg))
        }
    }

    return (
        <Container>
            <div className="chat-header">
                <div className="user-details">
                    <div className="avatar">
                        <img
                            onError={errorImageHandler}
                            src={currentChat.avatarImage || defaultAvatar}
                            alt="current Chat avatar"
                        />
                    </div>
                    <div className="username">
                        <h3>{currentChat.username}</h3>
                    </div>
                </div>
            </div>
            <div className="chat-messages">
                {messages.map((message) => {
                    return (
                        <div key={uuidv4()}>
                            <div
                                className={`message ${
                                    message?.fromSelf ? "sended" : "recieved"
                                }`}
                            >
                                {message?.message && (
                                    <div className="content ">
                                        <p>{message?.message}</p>
                                    </div>
                                )}
                                {message?.image && (
                                    <div className="content-image">
                                        <img
                                            src={message?.image}
                                            alt="sended"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>

            <ChatInput handleSendMessage={handleSendMessage} />
        </Container>
    )
}

export default ChatContainer

import {
    FETCH_ALL_PODCAST_START,
    FETCH_ALL_PODCAST_SUCCESS,
    FETCH_ALL_PODCAST_FAILED,
    FETCH_SINGLE_PODCAST_START,
    FETCH_SINGLE_PODCAST_SUCCESS,
    FETCH_SINGLE_PODCAST_FAILED,
} from "../constants/podcastConstant";

export const fetchSinglePodcastReducer = (state = { PodcastData: {} }, action) => {
    switch (action.type) {
        case FETCH_SINGLE_PODCAST_START:
            return { loading: true, podcastData: {} };
        case FETCH_SINGLE_PODCAST_SUCCESS:
            return {
                loading: false,
                podcastData: action.payload.PodcastData,
            };
        case FETCH_SINGLE_PODCAST_FAILED:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const fetchAllPodcastReducer = (state = { AllPodcastData: [] }, action) => {
    switch (action.type) {
        case FETCH_ALL_PODCAST_START:
            return { loading: true, AllPodcastData: [] };
        case FETCH_ALL_PODCAST_SUCCESS:
            return {
                loading: false,
                AllPodcastData: action.payload.AllPodcastData,
            };
        case FETCH_ALL_PODCAST_FAILED:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

import { forwardRef } from "react"
import styled from "styled-components"
import menuItems from "../../../DataList/menuItems"

import imag2 from "../../../static/images/bg/bg2.png"
const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 10% 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    top: auto;
    bottom: auto;
    margin: auto;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.imag});
    border: 9px solid var(--custom-orange-color);
    border-left: 0px;
    outline: var(--custom-orange-color);
    box-shadow: none;
    h3 {
        text-align: center;
        margin-bottom: 20px;
    }
    .pageContentOuter {
        text-align: left;
        /* margin: 0 auto; */
        height: 100%;
        overflow: auto;
        li {
            margin-bottom: 0.5rem !important;
        }
        .btn {
            text-decoration: none;
            color: #000;
            cursor: pointer;
            margin: 0;
            padding: 0;
        }
    }
`
export const MenuPage = forwardRef((props, ref) => {
    const goToPage = (pageNumber) => {
        props.book.current.pageFlip().flip(pageNumber + 2, "top")
    }

    return (
        <div
            className="page softPage"
            ref={ref}
            data-density={props.density | "soft"}
        >
            <PageWrapper imag={imag2}>
                <h3>Table of Contents</h3>
                <div className="pageContentOuter">
                    <div className="table-contents pg-2-container">
                        <ol>
                            {menuItems.map((item) => (
                                <>
                                    {" "}
                                    {item.pageNumber !== 13 && (
                                        <li key={item.pageNumber}>
                                            <button
                                                className="btn btn-link"
                                                onClick={() =>
                                                    goToPage(item.pageNumber)
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </li>
                                    )}
                                </>
                            ))}
                        </ol>
                    </div>
                </div>
            </PageWrapper>
        </div>
    )
})

import React from 'react'
import ReactDOM from 'react-dom/client'
import './static/css/index.css'
import './static/css/App.css'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './store/Store'

import * as Sentry from '@sentry/react'

Sentry.init({
    dsn: 'https://0bdcd8b4e00612016544a8b2b8aec80c@o4505232523853824.ingest.sentry.io/4505680163045376',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                'https://api.esotericcuriosa.com',
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <App />
    </Provider>
)

import { applyMiddleware, combineReducers, createStore } from "redux"
import { contentReducer, positionReducer } from "./reducers/contentReducer"
import {
    AllLabelsReducer,
    blogsAllByCategoryReducer,
    blogsAllByLabelReducer,
    blogSingleReducer,
    firstBlogReducer,
} from "./reducers/blogsReducer"
import {
    pdfModalReducer,
    loginModalReducer,
    profileModalReducer,
    blogModalReducer,
    subscriptionModalReducer,
    chatModalReducer,
    podcastModalReducer,
    adminChatReducer,
    notificationModalReducer,
    resetPasswordReducer,
    contactUsReducer,
    emailModalReducer,
} from "./reducers/modalReducer"
import {
    userLoginReducer,
    userRegisterReducer,
    userUpdateProfileReducer,
    allUserReducer,
    userChangePassReducer,
    userProfileReducer,
    userResetPassReducer,
} from "./reducers/userReducer"

import { bookReducer, singlePdfReducer } from "./reducers/bookReducers"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { messagesReducer } from "./reducers/chatReducer"
import {
    fetchAllPodcastReducer,
    fetchSinglePodcastReducer,
} from "./reducers/podcastReducer"
import {
    fetchAllNotificationReducer,
    fetchSingleNotificationReducer,
} from "./reducers/notificationReducer"

const reducer = combineReducers({
    // Auth
    userProfile: userProfileReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userChangePass: userChangePassReducer,
    resetPass: userResetPassReducer,
    //user

    allUsers: allUserReducer,

    //blogs
    blogsingle: blogSingleReducer,
    blogsAllByCategory: blogsAllByCategoryReducer,
    blogsAllByLabel: blogsAllByLabelReducer,
    AllLabels: AllLabelsReducer,
    firstBlog: firstBlogReducer,
    //blogsInfoFromLabel: blogsFromLabelReducer,

    // chat
    messages: messagesReducer,

    // podcast
    fetchSinglePodcast: fetchSinglePodcastReducer,
    fetchAllPodcast: fetchAllPodcastReducer,

    // Notification
    fetchSingleNotification: fetchSingleNotificationReducer,
    fetchAllNotification: fetchAllNotificationReducer,

    //modals
    pdfModal: pdfModalReducer,
    blogModal: blogModalReducer,
    loginModal: loginModalReducer,
    profileModal: profileModalReducer,
    subscriptionModal: subscriptionModalReducer,
    ChatModal: chatModalReducer,
    PodcastModal: podcastModalReducer,
    NotificationModal: notificationModalReducer,
    adminChatModal: adminChatReducer,
    contactModal: contactUsReducer,
    emailModal: emailModalReducer,
    resetPassword: resetPasswordReducer,

    //book
    book: bookReducer,
    singlePdf: singlePdfReducer,

    // content
    content: contentReducer,
    positionOfBlog: positionReducer,
})

const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
    userProfile: { profile: userInfoFromStorage },
}

console.log(process.env.REACT_APP_NODE_ENV)

export const store =
    process.env.REACT_APP_NODE_ENV === "development"
        ? createStore(
              reducer,
              initialState,
              composeWithDevTools(applyMiddleware(thunk))
          )
        : createStore(reducer, initialState, applyMiddleware(thunk))

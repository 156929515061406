import { deleteSingleUploadedFile, singleFileUpload } from './index'

export const handleFileUploadSingle = async fileData => {
    console.log('Deleting in fun')
    // Handle deletion (if necessary) concurrently using Promise.all
    const deletionPromise = fileData?.to_be_deleted_file_id
        ? deleteSingleUploadedFile({ fileID: fileData.to_be_deleted_file_id })
        : Promise.resolve() // Resolve immediately if no deletion needed

    // Upload the new file
    const uploadPromise = singleFileUpload({
        fileBuffer: fileData?.to_be_uploaded_buffer,
        purpose: fileData?.purpose,
    })

    // Wait for both deletion and upload to complete
    const [deletionResult, uploadData] = await Promise.all([
        deletionPromise,
        uploadPromise,
    ])

    return uploadData
}

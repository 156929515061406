import MainBook from "../components/book/MainBook"
import ProfileModal from "../components/modal/ProfileModal"
import styled from "styled-components"
import SubscriptionModal from "../components/modal/SubscriptionModal"
import PdfModal from "../components/modal/PdfModal"
import BlogModal from "../components/modal/BlogModal"
import PodcastModal from "../components/modal/podcastModal"
import { NavigationContainer } from "../components/Global/navigation/Container"
import Login from "../components/modal/LoginModal"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Chat from "../components/chatBody/Chat"
import NotificationModal from "../components/modal/NotificationModal"
import { useDispatch, useSelector } from "react-redux"
import {
    showAdminChatModal,
    showResetPasswordModal,
} from "../store/actions/modalActions"
import AdminChatModal from "../components/modal/AdminChatModal"
import { useEffect, useState } from "react"
import { getAllUsers, verifyEmailStep2 } from "../store/actions/userActions"
import adminChatImg from "../static/images/general/icons8-chat-room-24.png"
import { useLocation, useNavigate, useParams } from "react-router"
import { notifySuccess } from "../helpers/notifications/notifySuccess"
import ContactUsModal from "../components/modal/ContactUsModal"
import EmailModal from "../components/modal/EmailModal"
import ResetPasswordModal from "../components/modal/ResetPasswordModal"

const HomeScreen = () => {
    const userInfo = useSelector((state) => state.userProfile)
    const [verifyEmailToken, setVerifyEmailToken] = useState(null)
    const { profile } = userInfo
    const params = useParams()
    const location = useLocation()
    const { search, pathname } = location
    const { state } = params
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        state === "success" && notifySuccess("Payment Successful")
        state === "failed" && notifySuccess("Payment Failed")
        state && navigate("/")
    }, [state, navigate])

    // navigate added in dependency above
    useEffect(() => {
        if (pathname.split("/")[2] === "finalize_reset_password_email") {
            dispatch(showResetPasswordModal())
        }
    }, [dispatch, pathname])

    // in above dependency navigate, pathname are added in stable version
    useEffect(() => {
        if (pathname.split("/")[2] === "finalize_verify_email") {
            setVerifyEmailToken(search.split("=")[1])
        }
        verifyEmailToken &&
            dispatch(verifyEmailStep2({ token: verifyEmailToken }))
        verifyEmailToken && navigate("/")
    }, [verifyEmailToken, dispatch, navigate, pathname, search])

    // in above dependency navigate, pathname and search are added in stable version
    const usersList = useSelector((state) => state.allUsers)
    const { count } = usersList

    useEffect(() => {
        if (profile?.account_type === "Administrator") {
            dispatch(getAllUsers(count))
        }
    }, [profile, count, dispatch])

    const adminChat = () => {
        dispatch(showAdminChatModal())
    }

    return (
        <>
            <ContactUsModal />
            <ResetPasswordModal />
            <EmailModal />
            <NavigationContainer />
            <MainBook />
            <PdfModal />
            <SubscriptionModal />
            <ProfileModal />
            <BlogModal />
            <PodcastModal />
            <NotificationModal />
            <Login />
            <AdminChatModal />

            <ToastContainer />
            {profile && profile.account_type === "User" ? (
                <Chat />
            ) : (
                profile && (
                    <Container>
                        <img
                            src={adminChatImg}
                            alt="adminChatImgDown"
                            onClick={adminChat}
                        />
                    </Container>
                )
            )}
        </>
    )
}

const Container = styled.div`
    background-color: var(--custom-orange-color);
    position: fixed;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    bottom: 0;
    margin-bottom: 10px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    img {
        height: 30px;
    }
    cursor: pointer;
`

export default HomeScreen

import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "./Button"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllBlogsByLabel } from "../../store/actions/blogsActions"

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border: 1px solid var(--custom-border-color);
    border-radius: 2.5rem;
    background-color: var(--custom-light-bg);
    height: 3rem;
    width: 100%;
    max-width: 23.9375rem;
    align-items: center;
    padding: 0 1rem;
    &:hover,
    &:active {
        box-shadow: var(--custom-shadow);
    }
    input {
        border: none;
        outline: none;
        text-indent: 0.2rem;
        width: 100%;
        background: transparent;
    }
    button {
        border-radius: 2.5rem;
        padding: 0.1rem 0.3rem;
        &:hover,
        &:active {
            box-shadow: var(--custom-shadow);
        }
    }
`

export const Search = () => {
    const [searchParam, setSearchParam] = useState("")

    const handleSearchInputChange = (e) => {
        setSearchParam(e.target.value)
    }

    const { currentLabel } = useSelector((state) => state.blogsAllByLabel)

    const dispatch = useDispatch()
    const handleSearch = () => {
        if (currentLabel) {
            dispatch(fetchAllBlogsByLabel(currentLabel, 1, searchParam))
        }
        dispatch({
            type: "SEARCH",
            payload: searchParam,
        })
    }

    return (
        <Wrapper>
            <input
                type="search"
                placeholder="Search blogs ..."
                value={searchParam}
                onChange={handleSearchInputChange}
            />
            <Button
                textTransform={"uppercase"}
                fontSize={12}
                maxWidth={70}
                height={24}
                onClick={handleSearch}
            >
                Search
            </Button>
        </Wrapper>
    )
}

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Spinner } from '../Global/Spinner'
import Pagination from '../Global/pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllBlogsByLabel } from '../../store/actions/blogsActions'

const StyledComponent = styled.div`
    background-color: var(--custom-light-bg);
    height: inherit;
    position: relative;
    text-align: center;
    z-index: 2;
    padding: 0.5rem;
    .loadingCenter {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
    }
    .heading {
        font-size: 1.2rem;
        text-align: center;
        margin: 0;
        padding: 1rem 0;
        background-color: var(--custom-border-color);
        border: 4px solid var(--custom-border-color);
    }
    ul {
        list-style: none;
        height: 93%;
        backdrop-filter: blur(10px);
        margin: 0;
        padding: 0;
        overflow: auto;
        @media (max-width: 750px) {
            height: 90%;
        }
        .active {
            background-color: #ffffff85;
        }
        li {
            a {
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                gap: 1rem;
                padding: 0.5rem 1rem;
                border-bottom: 1px solid var(--custom-border-color);

                .detail {
                    display: flex;
                    flex-direction: column;
                }
                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                }
                h3 {
                    font-size: 1.1rem;
                }
                p {
                    font-size: 1rem;
                    text-align: left;
                }
            }
        }
    }
`
const BlogListComp = ({
    AllBlogsDataByLabel,
    blogId,
    loadBlogInfo,
    loading,
    count,
    currentLabel,
    recordsPerPage,
    paginationCurrentPage,
    setPaginationCurrentPage,
    dontFetch,
}) => {
    const dispatch = useDispatch()

    const { search } = useSelector((state) => state.blogModal)

    if (count < 11) {
        setPaginationCurrentPage(1)
    }

    useEffect(() => {
        !dontFetch &&
            currentLabel &&
            currentLabel !== null &&
            dispatch(
                fetchAllBlogsByLabel(
                    currentLabel,
                    paginationCurrentPage,
                    search
                )
            )
    }, [paginationCurrentPage])

    return (
        <StyledComponent>
            <h5 className='heading'>
                {AllBlogsDataByLabel && AllBlogsDataByLabel[0]?.label?.name}{' '}
                {AllBlogsDataByLabel.length === 0 && 'No blog found.'}
            </h5>
            <ul>
                {loading ? (
                    <div className='loadingCenter'>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {AllBlogsDataByLabel &&
                            AllBlogsDataByLabel.map((blogInfo, i) => {
                                return (
                                    blogInfo.publish && (
                                        <li
                                            className={`${
                                                blogId === blogInfo?.id
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            key={i}
                                        >
                                            <Link
                                                to={''}
                                                onClick={() =>
                                                    loadBlogInfo(
                                                        blogInfo?.id,
                                                        currentLabel
                                                    )
                                                }
                                            >
                                                <span>
                                                    <img
                                                        src={
                                                            blogInfo?.thumbnail
                                                                ?.file
                                                        }
                                                        alt=''
                                                    />
                                                </span>
                                                <div className='detail'>
                                                    <h3>{blogInfo?.title}</h3>
                                                </div>
                                            </Link>
                                        </li>
                                    )
                                )
                            })}
                        <Pagination
                            className='pagination-bar'
                            currentPage={paginationCurrentPage}
                            totalCount={count ? count : 0}
                            pageSize={recordsPerPage && recordsPerPage}
                            onPageChange={setPaginationCurrentPage}
                        />
                    </>
                )}
            </ul>
        </StyledComponent>
    )
}

export default BlogListComp

import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import styled from "styled-components"
import { post_logout } from "../../../api/EndPoints"
import axiosInstance from "../../../config/api/axois"
import { Button } from "../../Global/Button"
import { Spinner } from "../../Global/Spinner"
import {
    USER_LOGIN_RESET,
    USER_REGISTER_RESET,
    USER_UPDATE_PROFILE_RESET,
    CLEAR_USER_PROFILE,
} from "../../../store/constants/userProfileConstants"

const StyledContainer = styled.div`
    padding: 1rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--custom-main-background);
`

const LogoutContainer = () => {
    const [showSpinner, setShowSpinner] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleOnClickLogoutButton = (e) => {
        e.preventDefault()
        setShowSpinner(true)
        axiosInstance()
            .post(post_logout())
            .then(() => {
                localStorage.removeItem("access_token")
                localStorage.removeItem("refresh_token")
                dispatch({
                    type: USER_LOGIN_RESET,
                })
                dispatch({
                    type: USER_REGISTER_RESET,
                })
                dispatch({
                    type: USER_UPDATE_PROFILE_RESET,
                })
                dispatch({
                    type: CLEAR_USER_PROFILE,
                })
                navigate("/")
                setShowSpinner(false)
            })
    }

    return (
        <StyledContainer>
            {showSpinner ? (
                <Spinner />
            ) : (
                <Button
                    onClick={(e) => handleOnClickLogoutButton(e)}
                    height={54}
                    fontSize={16}
                    maxWidth={535}
                >
                    Proceed to Logout
                </Button>
            )}
        </StyledContainer>
    )
}

export default LogoutContainer

// BEGINNING API INTEGRATION

// const baseURL = 'http://127.0.0.1:8000'
const baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? `${process.env.REACT_APP_MAIN_SERVER_URL_PRODUCTION}/api/v1`
        : `${process.env.REACT_APP_MAIN_SERVER_URL_DEVELOPMENT}/api/v1`

const noOfContentOnSinglePage = 10

// AUTHENTICATION APIS

export const post_Login = () => `${baseURL}/auth/login/`
export const post_Signup = () => `${baseURL}/auth/register/`
export const post_logout = () => `${baseURL}/auth/logout/`
export const post_resetPasswordStep1 = () =>
    `${baseURL}/auth/initiate_reset_password_email/`
export const post_resetPasswordStep2 = () =>
    `${baseURL}/auth/finalize_reset_password_email/`
export const post_verifyEmailStep1 = () =>
    `${baseURL}/auth/initiate_verify_email/`
export const post_verifyEmailStep2 = () =>
    `${baseURL}/auth/finalize_verify_email/`
export const post_ChangePassword = () => `${baseURL}/auth/change_password/`
// export const forgotPassword = (email) => `${baseURL}/forgot_password/${email}`;

// AUTH ME
export const get_profile = () => `${baseURL}/auth/me/`
export const patch_profile = () => `${baseURL}/auth/me/`

// contact us
export const post_ContactUs = () => `${baseURL}/messaging/contact_support/`

// chat
export const post_ChatMessage = () => `${baseURL}/messaging/chat/message/`
export const patch_ChatMessage = (id) =>
    `${baseURL}/messaging/chat/message/${id}/`
export const get_ChatMessageById = (id) =>
    `${baseURL}/messaging/chat/message/${id}/`
export const get_AllChatMessage = () => `${baseURL}/messaging/chat/message/`
export const delete_ChatMessageById = (id) =>
    `${baseURL}/messaging/chat/message/${id}/`

// FILE UPLOAD
export const get_fetchAllFilesUploaded = () => `${baseURL}/upload`
export const post_singleFileUploaded = () => `${baseURL}/upload/`
export const post_batchFileUploaded = () => `${baseURL}/upload/batch_upload/`
export const patch_updateSingleFile = (id) => `${baseURL}/upload/${id}`
export const delete_SingleFile = (id) => `${baseURL}/upload/${id}`
export const delete_BatchFile = () => `${baseURL}/upload/batch_delete/`

// ADMIN APIS

// USER GENERAL SUBSCRIPTION
export const get_userCurrentGeneralSubscription = () =>
    `${baseURL}/user/subscription/`

// USER GENERAL SUBSCRIPTION
export const post_subscription = () => `${baseURL}/user/subscription/general/`

// All GENERAL SUBSCRIPTION
export const get_AllGeneralSubscriptionPlan = () =>
    `${baseURL}/subscription/general/`
export const get_SingleGeneralSubscriptionPlan = (id) =>
    `${baseURL}/subscription/general/${id}/`
export const post_CreateGeneralSubscriptionPlan = () =>
    `${baseURL}/subscription/general/`

// pdf
export const get_OnePdfBook = (id) => `${baseURL}/content/book/${id}/`

export const get_AllPdfBookBySpecial_purpose = (purpose, offset) =>
    `${baseURL}/content/book/?purpose=${purpose}&limit=${noOfContentOnSinglePage}&offset=${
        offset * noOfContentOnSinglePage
    }`

export const post_OnePdfBook = () => `${baseURL}/content/book/` //admin only
export const patch_OnePdfBook = (id) => `${baseURL}/content/book/${id}/` //admin only
export const delete_OnePdfBook = (id) => `${baseURL}/content/book/${id}/` //admin only

// blogs
export const get_OneBlog = (id) => `${baseURL}/content/blog/${id}/`
export const get_AllBlogsByCategory = (category, offset) =>
    `${baseURL}/content/blog/?category=${category}&limit=${noOfContentOnSinglePage}&offset=${
        offset * noOfContentOnSinglePage
    }&ordering=-date_created`
export const get_AllBlogsByLabel = (label, offset, search) =>
    search
        ? `${baseURL}/content/blog/?limit=10&label=${label}&offset=${offset}&ordering=-date_created&search=${search}`
        : `${baseURL}/content/blog/?limit=10&label=${label}&offset=${offset}&ordering=-date_created`

export const get_blogPosition = (id) => `${baseURL}/content/blog/?find=${id}`

export const get_AllLabels = () =>
    `${baseURL}/configuration/blog_label?limit=9999999`

// podcast
export const get_SinglePodcast = (id) => `${baseURL}/content/podcast/${id}/`
export const get_AllPodcast = () => `${baseURL}/content/podcast/`

// notifications
export const get_AllNotification = (offset) =>
    `${baseURL}/notification?limit=20&ordering=-date_added&offset=${offset}`
export const get_OneNotification = (id) => `${baseURL}/notification/${id}/`

// users
export const get_initial_users = () => `${baseURL}/user/`
export const get_all_users = (count) => `${baseURL}/user/?limit=${count}`

import {
  FETCH_MESSAGES_START,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  SEND_MESSAGE_START,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  ADD_MESSAGE,
} from "../constants/chatConstant"

export const messagesReducer = (
  state = {
    chats: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_MESSAGES_START:
      return { chats: state.chats, msgLoading: true }
    case FETCH_MESSAGES_SUCCESS:
      return { chats: action.payload, msgLoading: false }
    case FETCH_MESSAGES_FAILURE:
      return {
        chats: state.chats,
        loading: false,
        sendingMessageError: action.payload,
      }

    case SEND_MESSAGE_START:
      return { loading: true, chats: state.chats }
    case SEND_MESSAGE_SUCCESS:
      return {
        loading: false,
        chats: [...state.chats, action.payload],
      }
    case SEND_MESSAGE_FAILURE:
      return { loading: false, chats: state.chats, error: action.payload }

    case ADD_MESSAGE:
      return { chats: [...state.chats, action.payload] }

    default:
      return state
  }
}

import {
    FETCH_ALL_NOTIFICATION_START,
    FETCH_ALL_NOTIFICATION_SUCCESS,
    FETCH_ALL_NOTIFICATION_FAILED,
    FETCH_SINGLE_NOTIFICATION_START,
    FETCH_SINGLE_NOTIFICATION_SUCCESS,
    FETCH_SINGLE_NOTIFICATION_FAILED,
} from "../constants/notificationConstant";

export const fetchSingleNotificationReducer = (state = { singleNotificationData: {} }, action) => {
    switch (action.type) {
        case FETCH_SINGLE_NOTIFICATION_START:
            return { loading: true, singleNotificationData: {} };
        case FETCH_SINGLE_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                singleNotificationData: action.payload.singleNotificationData,
            };
        case FETCH_SINGLE_NOTIFICATION_FAILED:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const fetchAllNotificationReducer = (state = { AllNotificationData: [] }, action) => {
    switch (action.type) {
        case FETCH_ALL_NOTIFICATION_START:
            return { loading: true, AllNotificationData: [] };
        case FETCH_ALL_NOTIFICATION_SUCCESS:
            return {
                loading: false,
                AllNotificationData: action.payload.AllNotificationData,
            };
        case FETCH_ALL_NOTIFICATION_FAILED:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

import imag1 from "../static/images/bg/bg1.png"
import imag2 from "../static/images/bg/bg2.png"
import imag3 from "../static/images/bg/bg3.png"
import imag4 from "../static/images/bg/bg4.png"
import imag5 from "../static/images/bg/bg5.png"
const menuItems = [
  {
    name: "Dashboard of Nash Rambler",
    pageNumber: 1,
    imag: imag1,
  },
  {
    name: "Esoteric Scrutator",
    pageNumber: 2,
    imag: imag1,
  },
  {
    name: "Esoteric Library",
    pageNumber: 3,
    imag: imag2,
  },
  {
    name: "Ancestral Voices",
    pageNumber: 4,
    imag: imag2,
  },
  {
    name: "Esoteric Curiosa Blog Serials",
    pageNumber: 5,
    imag: imag3,
  },
  {
    name: "Esoteric Serials",
    pageNumber: 6,
    imag: imag3,
  },
  {
    name: "Foggshire Family Foibles",
    pageNumber: 7,
    imag: imag4,
  },
  {
    name: "Foggshire Serials",
    pageNumber: 8,
    imag: imag4,
  },
  {
    name: "Ruritanian Ramblings",
    pageNumber: 9,
    imag: imag5,
  },
  {
    name: "Ruritanian Serials",
    pageNumber: 10,
    imag: imag5,
  },

  {
    name: "Podcasts",
    pageNumber: 11,
    imag: imag1,
  },
  {
    name: "Notifications",
    pageNumber: 12,
    imag: imag1,
  },
  {
    name: "",
    pageNumber: 13,
    imag: imag1,
  },
]

export default menuItems

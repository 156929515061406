import { useSelector } from "react-redux"
import styled from "styled-components"

const StyledComponent = styled.div`
    background-color: var(--custom-main-bg);
    display: flex;
    justify-content: center;
    overflow: hidden;
`

export const MainContainer = (props) => {
    const userProfile = useSelector((state) => state.userProfile)
    const { profile } = userProfile
    return (
        <StyledComponent height={profile?.account_type}>
            {props.children}
        </StyledComponent>
    )
}

import {
  SET_BOOK,
  GET_BOOK,
  FETCH_SINGLE_PDF_SUCCESS,
  FETCH_SINGLE_PDF_FAILED,
  FETCH_SINGLE_PDF_START,
} from "../constants/bookConstants";

export const bookReducer = (state = { book: {} }, action) => {
  switch (action.type) {
    case SET_BOOK:
      return {
        book: action.payload,
      };
    case GET_BOOK:
      return state;
    default:
      return state;
  }
};

export const singlePdfReducer = (state = { pdf: {} }, action) => {
  switch (action.type) {
    case FETCH_SINGLE_PDF_START:
      return {
        loading: true,
        pdf: {},
      };
    case FETCH_SINGLE_PDF_SUCCESS:
      return {
        loading: false,
        pdf: action.payload.pdf,
      };
    case FETCH_SINGLE_PDF_FAILED:
      return { loading: false, pdf: {}, error: action.payload };
    default:
      return state;
  }
};

import { useCallback, useEffect, useState } from 'react'
import { Launcher } from 'react-chat-window'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { sendMessage, addMessage } from '../../store/actions/chatAction'
const AsideComponent = styled.aside`
    .sc-launcher {
        background-color: var(--custom-orange-color);
    }
    .sc-chat-window {
        width: 370px;
        height: calc(100% - 120px);
        max-height: 450px;
        position: fixed;
        right: 25px;
        bottom: 100px;
        box-sizing: border-box;
        box-shadow: 0px 7px 40px 2px rgb(148 149 150 / 30%);
        background: var(--custom-light-bg);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: 0.3s ease-in-out;
        border-radius: 10px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    .sc-message-list {
        background: transparent;
    }
    .sc-header {
        min-height: 60px;
        padding: 5px 10px;
        background-color: var(--custom-orange-color);
        img {
            width: 40px;
            height: 40px;
            border-radius: 2.4rem;
            padding: 0;
        }
    }
    .sc-header--close-button {
        width: 30px;
        height: 30px;
        img {
            width: 100%;
            height: 100%;
            padding: 0;
        }
    }
    svg {
        height: 30px;
        width: 30px;
    }
    .sc-user-input--button {
        margin-left: 0.5rem;
    }
`

const Chat = () => {
    const [messageList, setMessageList] = useState([])
    const userProfile = useSelector(state => state.userProfile)
    const { profile } = userProfile

    const chatMessages = useSelector(state => state.messages)
    const { chats } = chatMessages

    const dispatch = useDispatch()

    const [socket, setSocket] = useState(null)

    const initSocket = useCallback(
        socket_ => {
            socket_.onopen = function (e) {
                setSocket(socket_)
                console.log('Socket connected')
            }

            socket_.onclose = function (e) {
                setSocket(null)
            }

            socket_.onmessage = function (e) {
                const { data } = JSON.parse(e.data)
                if (data?.id) {
                    dispatch(addMessage(data))
                }
            }
        },
        [dispatch]
    )

    useEffect(() => {
        if (!socket) {
            initSocket(
                new WebSocket(
                    `${process.env.REACT_APP_MAIN_SERVER_URL_SOCKET}/ws/chat` +
                        '?token=' +
                        window.localStorage.getItem('access_token')
                )
            )
        }
    }, [initSocket, socket])

    useEffect(() => {
        if (chats && chats.length > 0) {
            const sortedMessages = chats
                .map(msg => ({
                    id: msg.id,
                    author: msg.owner === profile?.id ? 'me' : 'them',
                    type: 'text',
                    data: { text: msg.text },
                }))
                .sort((a, b) => a.id - b.id)

            setMessageList(sortedMessages)
        }
    }, [chats, profile])

    const _onMessageWasSent = message => {
        setMessageList([...messageList, message])
        dispatch(sendMessage(1, message.data.text))
    }

    return (
        <AsideComponent>
            <Launcher
                agentProfile={{
                    teamName: '',
                    imageUrl: profile?.logo,
                }}
                onMessageWasSent={_onMessageWasSent}
                messageList={messageList}
                showEmoji={false}
            />
        </AsideComponent>
    )
}

export default Chat

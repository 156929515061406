import {
  FETCH_CONTENT_FAILED,
  FETCH_CONTENT_START,
  FETCH_CONTENT_SUCCESS,
  FETCH_POSITION_FAILED,
  FETCH_POSITION_START,
  FETCH_POSITION_SUCCESS,
} from "../constants/contentConstants"

export const positionReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSITION_START:
      return {
        loading: true,
      }
    case FETCH_POSITION_SUCCESS:
      return {
        loading: false,
        page: action.payload.page,
        index_in_page: action.payload.index_in_page,
      }
    case FETCH_POSITION_FAILED:
      return {
        loading: false,
      }
    default:
      return state
  }
}

let initialState = {
  content: [
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "pdf_list",
      count: 0,
      pageNumber: 1,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "pdf_list",
      count: 0,
      pageNumber: 2,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "pdf_list",
      count: 0,
      pageNumber: 3,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_list",
      count: 0,
      pageNumber: 4,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_list",
      count: 0,
      pageNumber: 5,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_open",
      count: 0,
      pageNumber: 6,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_list",
      count: 0,
      pageNumber: 7,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_open",
      count: 0,
      pageNumber: 8,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_list",
      count: 0,
      pageNumber: 9,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "blog_open",
      count: 0,
      pageNumber: 10,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "podcast",
      count: 0,
      pageNumber: 11,
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
    {
      loading: false,
      title: "",
      thumbnail: {},
      publish: false,
      label: "",
      category: "",
      blog: "",
      pageClass: "notification",
      count: 0,
      pageNumber: 12,
      next: "",
      previous: "",
      pageContent: [],
      paginationNumber: 1,
      error: "",
    },
  ],
}

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_START:
      return {
        ...state,
        content: state.content.reduce((arr, thing) => {
          if (thing.pageNumber === action.payload.page) {
            arr.push({
              ...thing,
              loading: true,
            })
          } else {
            arr.push(thing)
          }
          return arr
        }, []),
      }
    case FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        content: state.content.reduce((arr, thing) => {
          if (thing.pageNumber === action.payload.page) {
            arr.push({
              ...thing,
              loading: false,
              title: action.payload.title,
              thumbnail: action.payload.thumbnail,
              publish: action.payload.publish,
              category: action.payload.category,
              blog: action.payload.blog,
              count: action.payload.count,
              pageContent: action.payload.pageContent,
              blogListId: action.payload.blogListId,
              blogListName: action.payload.blogListName,
              next: action.payload.next,
              previous: action.payload.previous,
            })
          } else {
            arr.push(thing)
          }
          return arr
        }, []),
      }
    case FETCH_CONTENT_FAILED:
      return {
        ...state,
        content: state.content.reduce((arr, thing) => {
          if (thing.pageNumber === action.payload.page) {
            arr.push({
              ...thing,
              error: action.payload.error,
            })
          } else {
            arr.push(thing)
          }
          return arr
        }, []),
      }
    default:
      return state
  }
}

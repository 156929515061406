import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";

const Wrapper = styled.div`
    border: 1px solid var(--custom-border-color);
    border-radius: 10px;
    padding: 1rem;
    h2 {
        font-size: 1.6rem;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 1rem 0;
    }
    .detail {
        font-size: 1rem;
        line-height: 1.2rem;
    }
`;
export const BlogPage = ({ blog }) => {
    // console.log("blog", blog);

    return (
        <div className="pageContentOuter">
            <Wrapper>{ReactHtmlParser(blog)}</Wrapper>
        </div>
    );
};

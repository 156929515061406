import React, { useState } from "react"
import styled from "styled-components"
import defaultAvatar from "../../../static/images/general/DefaultAvatar.png"

const Contacts = ({ contacts, changeChat }) => {
    const [currentSelected, setCurrentSelected] = useState("")
    const changeCurrentChat = (index, contact) => {
        setCurrentSelected(index)
        changeChat(contact)
    }
    const errorImageHandler = (e) => {
        e.target.src = defaultAvatar
    }

    return (
        <>
            <Container>
                <div className="brand">
                    <h3>Admin Chats</h3>ƒ
                </div>
                <div className="contacts">
                    {contacts.map((contact, index) => {
                        return (
                            <div
                                key={contact._id}
                                className={`contact ${
                                    index === currentSelected ? "selected" : ""
                                }`}
                                onClick={() =>
                                    changeCurrentChat(index, contact)
                                }
                            >
                                <div className="avatar">
                                    <img
                                        onError={errorImageHandler}
                                        src={
                                            contact.avatarImage
                                                ? contact.avatarImage
                                                : defaultAvatar
                                        }
                                        alt="avatar"
                                    />
                                </div>
                                <div className="username">
                                    <h3>{contact.username}</h3>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Container>
        </>
    )
}

const Container = styled.div`
    img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    padding: 0.3rem;
    border-radius: 50%;
  }
  display: grid;
  grid-template-rows: 10% 80% 10%;
  overflow: hidden;
  background-color: #FAF9F6;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    /* padding: 1.8rem 0; */
    h3 {
      color: #5A5A5A;
      text-transform: uppercase;
      display: none;
    }
    @media screen and (min-width: 720px) {
      h3 {
        display: block;
      }
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 0.8rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff15;
      color: #5A5A5A
      min-height: 5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s ease-in-out;
      .username {
        h3 {
          color: black;
       display: block;
        }
      }

      &:hover {
        filter: brightness(80%);
      }
    }
    .selected {
      background-color: #D3D3D3;
    }
    @media screen and (min-width: 720px) {
      .contact {
        flex-direction: row;
        justify-content: flex-start;

        padding: 0.4rem;
        gap: 1rem;
        .username {
          h3 {
            color: #5A5A5A;
            display: block;
          }
        }
      }
    }
  }
  .current-user {
    background-color: #FAF9F6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.2px solid #ffffff15;
    .avatar {
      height: 3.1rem;
      position: relative;
    }
    .username {
      h2 {
        color: white;
        display: none;
      }
    }
    @media screen and (min-width: 720px) {
      gap: 1rem;
      justify-content: flex-start;
      padding: 0.4rem;

      .username {
        h2 {
          font-size: 1rem;
          display: block;
          font-size: 1.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    grid-template-rows: 15% 70% 15%;
    .contacts {
      .contact {
        min-height: 4rem;
      }
    }
    h3 {
      display: none;
    }
  }

`

export default Contacts

import React from "react"
import { Button } from "../Global/Button"

export const SubscriptionSlide = ({
    title,
    price,
    handleSubscrition,
    year,
    id,
    description,
    timeSpan,
    is_public,
    nosub = false,
    disabled,
}) => {
    return (
        is_public && (
            <div
                className="p-4  planCard slick-slide"
                tabIndex="-1"
                data-slick-index="3"
                aria-hidden="true"
                key={id}
            >
                <div className="CardHeading">{title}</div>
                <div className="CardPrice">
                    <span className="mainPrice">
                        ${price}/ {timeSpan}
                        {""}
                        {year === "Yearly"
                            ? "Year"
                            : timeSpan !== 1
                            ? "Months"
                            : "Month"}
                    </span>
                </div>
                <p>{description}</p>

                <span className="PlanBtn">
                    <Button
                        textTransform="capitalize"
                        fontWeight={700}
                        fontSize={16}
                        maxWidth={170}
                        height={54}
                        onClick={handleSubscrition}
                        disabled={disabled ? disabled : nosub}
                    >
                        {nosub ? "Already subscribed" : "Subscribe"}
                    </Button>
                </span>
            </div>
        )
    )
}

import { get_AllNotification, get_OneNotification } from "../../api/EndPoints"
import AxiosInstance from "../../config/api/axois"
import {
    FETCH_ALL_NOTIFICATION_START,
    FETCH_ALL_NOTIFICATION_SUCCESS,
    FETCH_ALL_NOTIFICATION_FAILED,
    FETCH_SINGLE_NOTIFICATION_START,
    FETCH_SINGLE_NOTIFICATION_SUCCESS,
    FETCH_SINGLE_NOTIFICATION_FAILED,
} from "../constants/notificationConstant"
import { errorPayload } from "../helper/errorPayload"

export const fetchSingleNotification = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLE_NOTIFICATION_START })
        const { data } = await AxiosInstance().get(get_OneNotification(id))
        dispatch({
            type: FETCH_SINGLE_NOTIFICATION_SUCCESS,
            payload: { singleNotificationData: data },
        })
    } catch (error) {
        dispatch({
            type: FETCH_SINGLE_NOTIFICATION_FAILED,
            // payload: errorPayload(error),
        })
    }
}

export const fetchAllNotification = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_NOTIFICATION_START })
        const { data } = await AxiosInstance().get(get_AllNotification())
        dispatch({
            type: FETCH_ALL_NOTIFICATION_SUCCESS,
            payload: { AllNotificationData: data?.results },
        })
    } catch (error) {
        dispatch({
            type: FETCH_ALL_NOTIFICATION_FAILED,
            // payload: errorPayload(error),
        })
    }
}

import React, { useCallback, useRef, useState } from "react"
import HTMLFlipBook from "react-pageflip"
import styled from "styled-components"
import { PageCover } from "./mainBookComponents/CoverPage"
import bgimg1 from "../../static/images/general/bookcove-final-1.jpg"
import bgimg2 from "../../static/images/general/bookcove-final-2.jpg"
import { Page } from "./mainBookComponents/Page"
import menuItems from "../../DataList/menuItems"
import { MenuPage } from "./mainBookComponents/MenuPage"
import { useWindowSize } from "../Global/windowResizer"
import exLibraImg from "../../static/images/general/ex-libris.jpg"
import exLibraImg1 from "../../static/images/general/ex-libris1.jpg"

const StyledComponent = styled.div`
    max-height: 100vh;
    margin: 0 auto;
    @media (min-width: 1600px) {
        max-width: 1200px;
    }
    @media (min-width: 1400px) and (max-width: 1600px) {
        max-width: 1000px;
    }
    @media (min-width: 1100px) and (max-width: 1400px) {
        max-width: 800px;
    }
    .bookOuterContainer {
        margin: 0 auto;
        margin-top: 2rem;
    }
    .container-md {
        height: 80vh;
    }
    h2.wrap {
        background-color: #daa520;
        padding: 20px;
    }
    h2.wrap2 {
        background: transparent;
        background-color: transparent;

        padding: 20px;
    }
    .flip-book {
        .page-cover {
        }
        .softPage {
            background-color: #fff;
        }
    }
    .blankPage {
        background-color: white;
    }
`

const MainBook = () => {
    const [page, setPage] = useState(0)
    const flipRef = useRef()
    const size = useWindowSize()
    //initializing useParms

    const pages = menuItems
    const onFlip = useCallback(
        (e) => {
            if (e.data === 1 && size.width < 654) {
                setPage(e.data + 2)
            } else {
                setPage(e.data)
            }
        },
        [size.width]
    )

    return (
        <StyledComponent pageLength={pages?.length}>
            <div className="container-md bookOuterContainer">
                <div
                    className={
                        page > 1 && page < pages.length + 2 ? `wrap` : `wrap2`
                    }
                >
                    <HTMLFlipBook
                        width={550}
                        height={733}
                        size="stretch"
                        minWidth={315}
                        maxWidth={800}
                        minHeight={400}
                        maxHeight={1000}
                        flippingTime={1500}
                        maxShadowOpacity={0.5}
                        drawShadow={true}
                        showCover={true}
                        mobileScrollSupport={true}
                        onFlip={onFlip}
                        disableFlipByClick={true}
                        ref={flipRef}
                    >
                        {size.width >= 654 ? (
                            [0, 1].map((number, i) => (
                                <PageCover
                                    bgimg={i === 0 ? bgimg1 : exLibraImg}
                                    title={"."}
                                    key={number}
                                    pos="top"
                                />
                            ))
                        ) : (
                            <PageCover
                                bgimg={bgimg1}
                                title={"."}
                                key={0}
                                pos="top"
                            />
                        )}
                        <MenuPage book={flipRef} />
                        {pages.map((item, index) => (
                            <Page
                                key={index}
                                data={item}
                                className="page"
                                number={page}
                            />
                        ))}
                        {pages.length + (1 % 2) !== 0 ? (
                            <PageCover
                                bgimg={exLibraImg1}
                                title={"."}
                                key={pages.length - 1}
                                pos="bottom"
                            />
                        ) : (
                            [2, 1].map((number, i) => (
                                <PageCover
                                    bgimg={exLibraImg1}
                                    title={`. ${number}`}
                                    key={pages.length - number}
                                    pos="bottom"
                                />
                            ))
                        )}
                        <PageCover
                            bgimg={bgimg2}
                            title={"."}
                            key={pages.length}
                            pos="bottom"
                        />
                    </HTMLFlipBook>
                </div>
            </div>
        </StyledComponent>
    )
}

export default MainBook

import {
    SHOW_PDF_MODAL,
    HIDE_PDF_MODAL,
    SHOW_LOGIN_MODAL,
    HIDE_LOGIN_MODAL,
    SHOW_PROFILE_MODAL,
    HIDE_PROFILE_MODAL,
    SHOW_SUBSCRIPTION_MODAL,
    HIDE_SUBSCRIPTION_MODAL,
    SHOW_BLOG_MODAL,
    HIDE_BLOG_MODAL,
    SHOW_CHAT_MODAL,
    HIDE_CHAT_MODAL,
    SHOW_ADMIN_CHAT_MODAL,
    HIDE_ADMIN_CHAT_MODAL,
    SHOW_PODCAST_MODAL,
    HIDE_PODCAST_MODAL,
    SHOW_NOTIFICATION_MODAL,
    HIDE_NOTIFICATION_MODAL,
    SHOW_CONTACTUS_MODAL,
    CONTACT_US_FORM_START,
    CONTACT_US_FORM_SUCCESS,
    CONTACT_US_FORM_FAIL,
    HIDE_CONTACTUS_MODAL,
    SHOW_EMAIL_MODAL,
    HIDE_EMAIL_MODAL,
    SHOW_RESET_PASSWORD_MODAL,
    HIDE_RESET_PASSWORD_MODAL,
} from "../constants/modalConstants"

export const emailModalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_EMAIL_MODAL:
            return {
                show: true,
            }
        case HIDE_EMAIL_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const pdfModalReducer = (
    state = { show: false, pdfId: null },
    action
) => {
    switch (action.type) {
        case SHOW_PDF_MODAL:
            return {
                show: true,
                pdfId: action.payload.id,
            }
        case HIDE_PDF_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const blogModalReducer = (
    state = { show: false, blogId: null, labelgetId: 1, search: "" },
    action
) => {
    switch (action.type) {
        case SHOW_BLOG_MODAL:
            return {
                show: true,
                blogId: action.payload.id,
                labelgetId: action.payload.labelgetId,
                search: state.search,
            }
        case "SEARCH":
            return {
                show: state.show,
                blogId: state.blogId,
                labelgetId: state.labelgetId,
                search: action.payload,
            }
        case HIDE_BLOG_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}
export const podcastModalReducer = (
    state = { show: false, podcastgetId: null },
    action
) => {
    switch (action.type) {
        case SHOW_PODCAST_MODAL:
            return {
                show: true,
                podcastgetId: action.payload.podcastgetId,
            }
        case HIDE_PODCAST_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}
export const notificationModalReducer = (
    state = { show: false, notificationgetId: null },
    action
) => {
    switch (action.type) {
        case SHOW_NOTIFICATION_MODAL:
            return {
                show: true,
                notificationgetId: action.payload.notificationgetId,
            }
        case HIDE_NOTIFICATION_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const loginModalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_LOGIN_MODAL:
            return {
                show: true,
            }
        case HIDE_LOGIN_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const profileModalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_PROFILE_MODAL:
            return {
                show: true,
            }
        case HIDE_PROFILE_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const subscriptionModalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_SUBSCRIPTION_MODAL:
            return {
                show: true,
            }
        case HIDE_SUBSCRIPTION_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}
export const chatModalReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_CHAT_MODAL:
            return {
                show: true,
            }
        case HIDE_CHAT_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const adminChatReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_ADMIN_CHAT_MODAL:
            return {
                show: true,
            }
        case HIDE_ADMIN_CHAT_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const resetPasswordReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_RESET_PASSWORD_MODAL:
            return {
                show: true,
            }
        case HIDE_RESET_PASSWORD_MODAL:
            return {
                show: false,
            }
        default:
            return state
    }
}

export const contactUsReducer = (state = { show: false }, action) => {
    switch (action.type) {
        case SHOW_CONTACTUS_MODAL:
            return {
                show: true,
            }
        case HIDE_CONTACTUS_MODAL:
            return {
                show: false,
            }

        case CONTACT_US_FORM_START:
            return { show: state.show, loading: true }
        case CONTACT_US_FORM_SUCCESS:
            return {
                show: state.show,
                loading: false,
                message: action.payload,
            }
        case CONTACT_US_FORM_FAIL:
            return {
                show: state.show,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

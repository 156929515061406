import { notifyFailure } from '../../helpers/notifications/notifyFailure'
import { notifyInfo } from '../../helpers/notifications/notifyInfo'

export const errorPayload = error => {
    Object.keys(error.response.data).map(item => {
        if (Array.isArray(error.response.data[item])) {
            if (error.response.data[item][0].includes('token')) {
                localStorage.removeItem('access_token')
                notifyInfo('Please try logging in again.')
            } else {
                notifyFailure(error.response.data[item][0])
            }

            return error.response.data[item][0]
        } else {
            if (error.response.data[item].includes('token')) {
                localStorage.removeItem('access_token')
                notifyInfo('Please try logging in again.')
            } else {
                notifyFailure(error.response.data[item])
            }
            return error.response.data[item]
        }
    })
}

export const FETCH_SINGLE_BLOG_START = "FETCH_SINGLE_BLOG_START"
export const FETCH_SINGLE_BLOG_SUCCESS = "FETCH_SINGLE_BLOG_SUCCESS"
export const FETCH_SINGLE_BLOG_FAILED = "FETCH_SINGLE_BLOG_FAILED"

export const FETCH_ALL_BLOGS_BY_CATEGORY_START =
    "FETCH_ALL_BLOGS_BY_CATEGORY_START"
export const FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS =
    "FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS"
export const FETCH_ALL_BLOGS_BY_CATEGORY_FAILED =
    "FETCH_ALL_BLOGS_BY_CATEGORY_FAILED"

export const FETCH_ALL_BLOGS_BY_LABEL_START = "FETCH_ALL_BLOGS_BY_LABEL_START"
export const FETCH_ALL_BLOGS_BY_LABEL_SUCCESS =
    "FETCH_ALL_BLOGS_BY_LABEL_SUCCESS"
export const FETCH_ALL_BLOGS_BY_LABEL_FAILED = "FETCH_ALL_BLOGS_BY_LABEL_FAILED"

export const FETCH_ALL_LABEL_START = "FETCH_ALL_LABEL_START"
export const FETCH_ALL_LABEL_SUCCESS = "FETCH_ALL_LABEL_SUCCESS"
export const FETCH_ALL_LABEL_FAILED = "FETCH_ALL_LABEL_FAILED"

export const FETCH_BLOG_FROM_LABEL_START = "FETCH_BLOG_FROM_LABEL_START"
export const FETCH_BLOG_FROM_LABEL_SUCCESS = "FETCH_BLOG_FROM_LABEL_SUCCESS"
export const FETCH_BLOG_FROM_LABEL_FAILED = "FETCH_BLOG_FROM_LABEL_FAILED"

export const FETCH_FIRST_BLOG_TRUE = "FETCH_FIRST_BLOG_TRUE"
export const FETCH_FIRST_BLOG_FALSE = "FETCH_FIRST_BLOG_FALSE"

import {
    FETCH_ALL_LABEL_FAILED,
    FETCH_ALL_LABEL_START,
    FETCH_ALL_LABEL_SUCCESS,
    FETCH_BLOG_FROM_LABEL_FAILED,
    FETCH_BLOG_FROM_LABEL_START,
    FETCH_BLOG_FROM_LABEL_SUCCESS,
    FETCH_SINGLE_BLOG_START,
    FETCH_SINGLE_BLOG_SUCCESS,
    FETCH_SINGLE_BLOG_FAILED,
    FETCH_ALL_BLOGS_BY_CATEGORY_START,
    FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS,
    FETCH_ALL_BLOGS_BY_CATEGORY_FAILED,
    FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
    FETCH_ALL_BLOGS_BY_LABEL_START,
    FETCH_ALL_BLOGS_BY_LABEL_FAILED,
    FETCH_FIRST_BLOG_TRUE,
    FETCH_FIRST_BLOG_FALSE,
} from "../constants/blogsConstants"

export const blogSingleReducer = (state = { blog: {} }, action) => {
    switch (action.type) {
        case FETCH_SINGLE_BLOG_START:
            return { loading: true, blog: {} }
        case FETCH_SINGLE_BLOG_SUCCESS:
            return {
                loading: false,
                blog: action.payload.blog,
            }
        case FETCH_SINGLE_BLOG_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const blogsAllByCategoryReducer = (
    state = { AllBlogsData: [] },
    action
) => {
    switch (action.type) {
        case FETCH_ALL_BLOGS_BY_CATEGORY_START:
            return { loading: true, AllBlogsData: [] }
        case FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS:
            return {
                loading: false,
                AllBlogsData: action.payload.AllBlogsData,
            }
        case FETCH_ALL_BLOGS_BY_CATEGORY_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
export const blogsAllByLabelReducer = (
    state = { AllBlogsData: [] },
    action
) => {
    switch (action.type) {
        case FETCH_ALL_BLOGS_BY_LABEL_START:
            return { loading: true, AllBlogsData: [] }
        case FETCH_ALL_BLOGS_BY_LABEL_SUCCESS:
            return {
                loading: false,
                AllBlogsData: action.payload.AllBlogsData,
                count: action.payload.count,
                currentLabel: action.payload.currentLabel,
            }
        case FETCH_ALL_BLOGS_BY_LABEL_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const AllLabelsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_ALL_LABEL_START:
            return { loading: true, AllLabels: [] }
        case FETCH_ALL_LABEL_SUCCESS:
            return {
                loading: false,
                AllLabels: action.payload.AllLabels,
            }
        case FETCH_ALL_LABEL_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const blogsFromLabelReducer = (
    state = { blogsInfo: [], requestedBlogId: "" },
    action
) => {
    switch (action.type) {
        case FETCH_BLOG_FROM_LABEL_START:
            return { loading: true, blogsInfo: [] }
        case FETCH_BLOG_FROM_LABEL_SUCCESS:
            return {
                loading: false,
                blogsInfo: action.payload?.blogsInfo,
                requestedBlogId: action.payload?.requestedBlogId,
            }
        case FETCH_BLOG_FROM_LABEL_FAILED:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const firstBlogReducer = (state = { firstBlog: false }, action) => {
    switch (action.type) {
        case FETCH_FIRST_BLOG_TRUE:
            return { firstBlog: true }
        case FETCH_FIRST_BLOG_FALSE:
            return { firstBlog: false }
        default:
            return state
    }
}

import {
  SHOW_PDF_MODAL,
  HIDE_PDF_MODAL,
  SHOW_LOGIN_MODAL,
  HIDE_LOGIN_MODAL,
  SHOW_PROFILE_MODAL,
  HIDE_PROFILE_MODAL,
  SHOW_SUBSCRIPTION_MODAL,
  HIDE_SUBSCRIPTION_MODAL,
  SHOW_BLOG_MODAL,
  HIDE_BLOG_MODAL,
  SHOW_CHAT_MODAL,
  HIDE_CHAT_MODAL,
  SHOW_ADMIN_CHAT_MODAL,
  HIDE_ADMIN_CHAT_MODAL,
  SHOW_PODCAST_MODAL,
  HIDE_PODCAST_MODAL,
  SHOW_NOTIFICATION_MODAL,
  HIDE_NOTIFICATION_MODAL,
  SHOW_CONTACTUS_MODAL,
  HIDE_CONTACTUS_MODAL,
  SHOW_EMAIL_MODAL,
  HIDE_EMAIL_MODAL,
  SHOW_RESET_PASSWORD_MODAL,
  HIDE_RESET_PASSWORD_MODAL,
} from "../constants/modalConstants"

// PDF
export const showPdfModal = (id) => async (dispatch) => {
  dispatch({ type: SHOW_PDF_MODAL, payload: { id } })
}

export const hidePdfModal = () => async (dispatch) => {
  dispatch({ type: HIDE_PDF_MODAL })
}

// LOGIN/SIGNUP
export const showLoginModal = () => async (dispatch) => {
  dispatch({ type: SHOW_LOGIN_MODAL })
}

export const hideLoginModal = () => async (dispatch) => {
  dispatch({ type: HIDE_LOGIN_MODAL })
}

// profile
export const showProfileModal = () => async (dispatch) => {
  dispatch({ type: SHOW_PROFILE_MODAL })
}

export const hideProfileModal = () => async (dispatch) => {
  dispatch({ type: HIDE_PROFILE_MODAL })
}

// subscription
export const showSubscriptionModal = () => async (dispatch) => {
  dispatch({ type: SHOW_SUBSCRIPTION_MODAL })
}

export const hideSubscriptionModal = () => async (dispatch) => {
  dispatch({ type: HIDE_SUBSCRIPTION_MODAL })
}

// blog
// subscription
export const showBlogModal = (id, labelgetId) => async (dispatch) => {
  dispatch({ type: SHOW_BLOG_MODAL, payload: { id, labelgetId } })
}

export const hideBlogModal = () => async (dispatch) => {
  dispatch({ type: HIDE_BLOG_MODAL })
}
// podcast
export const showPodcastModal = (podcastgetId) => async (dispatch) => {
  dispatch({ type: SHOW_PODCAST_MODAL, payload: { podcastgetId } })
}

export const hidePodcastModal = () => async (dispatch) => {
  dispatch({ type: HIDE_PODCAST_MODAL })
}
// notification
export const showNotificationModal =
  (notificationgetId) => async (dispatch) => {
    dispatch({ type: SHOW_NOTIFICATION_MODAL, payload: { notificationgetId } })
  }

export const hideNotificationModal = () => async (dispatch) => {
  dispatch({ type: HIDE_NOTIFICATION_MODAL })
}

// chatBox
export const showChatModal = () => async (dispatch) => {
  dispatch({ type: SHOW_CHAT_MODAL })
}

export const hideChatModal = () => async (dispatch) => {
  dispatch({ type: HIDE_CHAT_MODAL })
}

// chatBox
export const showAdminChatModal = () => async (dispatch) => {
  dispatch({ type: SHOW_ADMIN_CHAT_MODAL })
}

export const hideAdminChatModal = () => async (dispatch) => {
  dispatch({ type: HIDE_ADMIN_CHAT_MODAL })
}

// ContactUs
export const showContactModal = () => async (dispatch) => {
  dispatch({ type: SHOW_CONTACTUS_MODAL })
}

export const hideContactModal = () => async (dispatch) => {
  dispatch({ type: HIDE_CONTACTUS_MODAL })
}

// email
export const showEmailModal = () => async (dispatch) => {
  dispatch({ type: SHOW_EMAIL_MODAL })
}

export const hideEmailModal = () => async (dispatch) => {
  dispatch({ type: HIDE_EMAIL_MODAL })
}

// resetPass
export const showResetPasswordModal = () => async (dispatch) => {
  dispatch({ type: SHOW_RESET_PASSWORD_MODAL })
}

export const hideResetPasswordModal = () => async (dispatch) => {
  dispatch({ type: HIDE_RESET_PASSWORD_MODAL })
}

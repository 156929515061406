import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { GlobalRoutes } from './routes/GlobalRoutes'
import { showLoginModal } from './store/actions/modalActions'
import { fetchUserProfile } from './store/actions/userActions'
import { fetchMessages } from './store/actions/chatAction'

const App = () => {
    const dispatch = useDispatch()
    const Auth = window.localStorage.getItem('access_token')

    useEffect(() => {
        Auth && dispatch(fetchUserProfile())
        Auth && dispatch(fetchMessages())
        !Auth && dispatch(showLoginModal())
    }, [Auth, dispatch])
    return (
        <div>
            <BrowserRouter>
                <GlobalRoutes />
            </BrowserRouter>
        </div>
    )
}

export default App

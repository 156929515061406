import React from "react";
import { hideEmailModal } from "../../store/actions/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { ModalComponent } from "../Global/Modal";
import { CloseBtn } from "../Global/CloseBtn";
import { verifyEmailStep1 } from "../../store/actions/userActions";
import styled from "styled-components";
import { Button } from "../Global/Button";

const customStyles = {
  content: {
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%",
  },
};

const Wrapper = styled.div`
  text-align: center;
  width: 80%;
  padding: 15% 0;
  margin: 0 auto;
  backgorund-color: white;
`;

const EmailModal = () => {
  const dispatch = useDispatch();
  const modalInfo = useSelector((state) => state.emailModal);
  const { show } = modalInfo;

  function closeModal() {
    dispatch(hideEmailModal());
  }

  const uri =
    process.env.REACT_APP_NODE_ENV === "production"
      ? `${process.env.REACT_APP_PRODUCTION}`
      : process.env.REACT_APP_LOCAL_HOST;

  const verifyEmail = () => {
    dispatch(
      verifyEmailStep1({ page_uri: `${uri}/auth/finalize_verify_email` })
    );
  };

  return (
    <ModalComponent
      isOpen={show}
      onRequestClose={closeModal}
      modalLabel={"Email Modal"}
      classNameFromProps=""
      stylesFromProps={customStyles}
    >
      <div>
        <Wrapper>
          <h1 className="main_title">Email Verification</h1>
          <br />
          <p>Please verify your email address to enjoy Esoteric Curiousa.</p>
          <br />

          <Button
            textTransform={"uppercase"}
            fontSize={16}
            maxWidth={200}
            border={"transparent"}
            height={41}
            onClick={verifyEmail}
          >
            Verify email
          </Button>
        </Wrapper>
        <CloseBtn handleOnClickClose={closeModal} />
      </div>
    </ModalComponent>
  );
};

export default EmailModal;

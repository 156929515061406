import axiosInstance from "../../config/api/axois"
import {
    FETCH_SINGLE_PDF_START,
    FETCH_SINGLE_PDF_SUCCESS,
    FETCH_SINGLE_PDF_FAILED,
} from "../constants/bookConstants"

import { get_OnePdfBook } from "../../api/EndPoints"
import { errorPayload } from "../helper/errorPayload"

export const fetchSinglePDF = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLE_PDF_START })

        const { data } = await axiosInstance().get(get_OnePdfBook(id))

        dispatch({
            type: FETCH_SINGLE_PDF_SUCCESS,
            payload: { pdf: data },
        })
    } catch (error) {
        dispatch({
            type: FETCH_SINGLE_PDF_FAILED,
            // payload: errorPayload(error),
        })
    }
}

import { get_AllPodcast, get_SinglePodcast } from "../../api/EndPoints"
import AxiosInstance from "../../config/api/axois"
import {
    FETCH_ALL_PODCAST_FAILED,
    FETCH_ALL_PODCAST_START,
    FETCH_ALL_PODCAST_SUCCESS,
    FETCH_SINGLE_PODCAST_FAILED,
    FETCH_SINGLE_PODCAST_START,
    FETCH_SINGLE_PODCAST_SUCCESS,
} from "../constants/podcastConstant"
import { errorPayload } from "../helper/errorPayload"

export const fetchSinglePodcast = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLE_PODCAST_START })
        const { data } = await AxiosInstance().get(get_SinglePodcast(id))
        dispatch({
            type: FETCH_SINGLE_PODCAST_SUCCESS,
            payload: { PodcastData: data },
        })
    } catch (error) {
        dispatch({
            type: FETCH_SINGLE_PODCAST_FAILED,
            // payload: errorPayload(error),
        })
    }
}

export const fetchAllPodcast = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_PODCAST_START })
        const { data } = await AxiosInstance().get(get_AllPodcast())

        dispatch({
            type: FETCH_ALL_PODCAST_SUCCESS,
            payload: { AllPodcastData: data?.results },
        })
    } catch (error) {
        dispatch({
            type: FETCH_ALL_PODCAST_FAILED,
            // payload: errorPayload(error),
        })
    }
}

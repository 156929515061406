import { useState } from "react"
import { ModalComponent } from "../Global/Modal"
import styled from "styled-components"
import email from "../../static/images/Auth/email.png"
import { useDispatch, useSelector } from "react-redux"
import { notifyFailure } from "../../helpers/notifications/notifyFailure"
import { FormComponent } from "../Authentication/components/FormElement"
import { InputComponent } from "../Authentication/components/InputELement"
import { HandleOnChangeInput } from "../../helpers/formInput/HandleOnChangeInput"
import { Button } from "../Global/Button"
import { Spinner } from "../Global/Spinner"
import { SizedBox } from "../Global/SizedBox"
import { CloseBtn } from "../Global/CloseBtn"
import { contactus } from "../../store/actions/userActions"
import { hideContactModal } from "../../store/actions/modalActions"

const PageWrapper = styled.div`
    padding: 10% 15%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    top: auto;
    bottom: auto;
    margin: auto;
    text-align: center;
    background-color: white;s
    box-shadow: none;
    h2 {
        text-align: center;
       
    }
    .pageContentOuter {
        text-align: center;
        padding: 0 0.5rem;
        overflow: auto;

        form {
            max-width: 100%;
            width: 100%;
            gap: 0.5rem;

            .inputOuter {
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
                @media (max-width: 810px) {
                    width: 100%;
                    input {
                        max-width: 100%;
                    }
                }
                label {
                    width: 150px;
                }
                textarea {
                    border: none;
                    outline: none;
                    background-color: var(--custom-transparent);
                    width: 100%;
                    max-width: 535px;
                    height: 100px;
                    letter-spacing: 1px;
                    margin-top: 0rem;
                    margin-bottom: 1rem;
                    border-bottom: 1px solid var(--custom-border-color);
                    color: var(--custom-txt-color);
                    font-size: var(--font-16);
                    text-indent: 10px;
                }
            }
        }
        .socialIcons {
            ul {
                margin: 0;
                padding: 0;
                li {
                    margin: 0;

                    display: flex;

                    justify-content: center;
                    img {
                        width: 40px;
                        height: 40px;
                    }
                    a {
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        margin-left: 1rem;
                        color: var(--custom-orange-color);
                        &:hover {
                            color: var(--custom-black);
                        }
                    }
                }
            }
        }
        p {
            font-weight: 600;
            font-size: larger;
        }
    }
`

const ContactUsModal = () => {
    const { show } = useSelector((state) => state.contactModal)

    const { loading: loadingContact } = useSelector(
        (state) => state.contactModal
    )

    //dummy values for subject & contact number
    const dummySubject = "No Subject"
    const dummyContactNumber = "+1 202 555 0156"

    const [data, setData] = useState({
        sender_name: "",
        sender_email: "",
        content: "",
    })

    const dispatch = useDispatch()

    const validateFields = () => {
        let state = true
        let fields = ["sender_name", "sender_email", "content"]
        for (let field of fields) {
            if (!data[field]) {
                notifyFailure(`${field} is required`)
                state = false
            }
        }

        return state
    }

    // handling submit button
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!validateFields()) {
            return
        }
        const final_data = {
            sender_name: data.sender_name,
            sender_email: data.sender_email,
            content: data.content,
            subject: dummySubject,
            sender_contact_number: dummyContactNumber,
        }

        dispatch(contactus(final_data))

        setData({
            sender_name: "",
            sender_email: "",
            content: "",
        })
    }

    function closeModal() {
        dispatch(hideContactModal())
    }

    return (
        <ModalComponent
            isOpen={show}
            onRequestClose={closeModal}
            modalLabel={""}
            classNameFromProps=""
        >
            <PageWrapper>
                <h2>Contact Us</h2>
                <div className="pageContentOuter">
                    <FormComponent>
                        <p>
                            If You have any Website specific questions or
                            comments, please fill out the form below. <br />{" "}
                            <br />
                            Our Esoteric Curiosa Administrator will respond back
                            to you within 24 hours. We appreciate your
                            Subscription to The Esoteric Curiosa.
                        </p>
                        <div className="inputOuter">
                            <label htmlFor="sender_name">Username</label>
                            <InputComponent
                                id="sender_name"
                                type="text"
                                height={40}
                                value={data?.sender_name}
                                onChange={(e) =>
                                    HandleOnChangeInput(
                                        e,
                                        "sender_name",
                                        setData,
                                        data
                                    )
                                }
                            />
                        </div>
                        <div className="inputOuter">
                            <label htmlFor="email">Email</label>
                            <InputComponent
                                id="email"
                                type="email"
                                height={40}
                                value={data?.sender_email}
                                onChange={(e) =>
                                    HandleOnChangeInput(
                                        e,
                                        "sender_email",
                                        setData,
                                        data
                                    )
                                }
                            />
                        </div>
                        {/* <div className="inputOuter">
                            <label htmlFor="subject:"",
                            ">Subject</label>
                            <InputComponent
                                id="subject"
                                type="text"
                                height={40}
                                value={data?.subject}
                                onChange={(e) =>
                                    HandleOnChangeInput(
                                        e,
                                        "subject",
                                        setData,
                                        data
                                    )
                                }
                            />
                        </div> */}
                        <div className="inputOuter">
                            <label htmlFor="sender_name">Message</label>
                            <textarea
                                id="content"
                                type="text"
                                height={40}
                                value={data?.content}
                                onChange={(e) =>
                                    HandleOnChangeInput(
                                        e,
                                        "content",
                                        setData,
                                        data
                                    )
                                }
                            />
                        </div>

                        {/* <div className="inputOuter">
                            <label htmlFor="number">Contact Number</label>
                            <InputComponent
                                id="number"
                                type="tel"
                                height={40}
                                value={data?.sender_contact_number}
                                onChange={(e) =>
                                    HandleOnChangeInput(
                                        e,
                                        "sender_contact_number",
                                        setData,
                                        data
                                    )
                                }
                            />
                        </div> */}
                    </FormComponent>
                    <SizedBox height={2} />
                    {loadingContact ? (
                        <Spinner size={2.0} />
                    ) : (
                        <Button
                            maxWidth={200}
                            height={54}
                            fontSize={16}
                            textTransform="uppercase"
                            paddingTopBottom={0.5}
                            paddingLeftRight={1.5}
                            onClick={(e) => handleSubmit(e)}
                        >
                            SUBMIT
                        </Button>
                    )}
                    <SizedBox height={2} />
                    <div className="socialIcons">
                        <ul>
                            <SizedBox height={2} />
                            <li>
                                <img src={email} alt="email" />
                                <a href="mailto:website_esotericcuriosa@outlook.com">
                                    website_esotericcuriosa@outlook.com
                                </a>
                            </li>
                            <SizedBox height={2} />
                            <SizedBox height={2} />
                        </ul>
                    </div>
                </div>
            </PageWrapper>
            <CloseBtn handleOnClickClose={closeModal} />
        </ModalComponent>
    )
}

export default ContactUsModal

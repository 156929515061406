import Modal from "react-modal"

Modal.setAppElement("#root")

export const ModalComponent = ({
    children,
    isOpen,
    callbackOnAfterOpen,
    callbackCloseModal,
    modalLabel = "",
    stylesFromProps,
    classNameFromProps,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={callbackOnAfterOpen}
            onRequestClose={callbackCloseModal}
            style={stylesFromProps}
            contentLabel={modalLabel}
            className={classNameFromProps}
        >
            {children}
        </Modal>
    )
}

import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"
import BlogComp from "../components/blogComponents/BlogComp"
import BlogListComp from "../components/blogComponents/BlogListComp"
import Labels from "../components/blogComponents/Labels"
import { TopBar } from "../components/Global/TopBar"
import LabelsImg from "../static/images/Auth/3664039@0.png"
import { fetchAllBlogsByLabel } from "../store/actions/blogsActions"
import { showBlogModal } from "../store/actions/modalActions"

const StyledComponent = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;

    #labelNav {
        height: 10vh;

        display: flex;
        flex-direction: column;
        position: fixed;
        left: auto;
        right: 5px;
        top: 70px;
        botton: 5%;
        z-index: 1;
        animation-duration: 500ms;
        animation-name: showPopNav;
        animation-timing-function: ease-in;
        width: 100%;
        max-width: fit-content;

        @media (max-width: 750px) {
            top: 127px;
        }
        @keyframes showPopNav {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            background-color: var(--custom-light-bg);
            .active {
                background-color: #ffffff85;
            }
            li {
                cursor: pointer;
                backdrop-filter: blur(10px);
                a {
                    border: 1px solid var(--custom-border-color);
                    /* border-radius: 2.4rem; */
                    padding: 0.5rem 1rem;
                    display: flex;
                    justify-content: start;
                    width: 100%;
                    color: #000;
                    text-decoration: none;
                    span.badge {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 2.4rem;
                        background-color: var(--custom-orange-color);
                        color: #fff;
                    }
                }
            }
            ::-webkit-scrollbar {
                background: var(--custom-input-border) !important;
                height: 4px !important;
                width: 4px !important;
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 5px !important;
            }
            /* Track */
            ::-webkit-scrollbar-track {
                background: var(--custom-input-border) !important;
                height: 4px !important;
                width: 4px !important;
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 5px !important;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: var(--custom-orange-color) !important;
                height: 0px !important;
                width: 0px !important;
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 5px !important;
            }
            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: var(--custom-orange-color) !important;
                height: 4px !important;
                width: 0px !important;
                margin: 0 !important;
                padding: 0 !important;
                border-radius: 5px !important;
            }
        }

        .logout-button {
            text-decoration: none;
            font-size: var(--font-14);
            font-family: var(--font-1);
            font-weight: 500;
            display: flex;
            align-items: center;
            padding: 0.3rem 0 0.3rem 0.4rem;
            border: 1px solid var(--custom-border-color);
            border-radius: 2.4rem;
            background-color: var(--custom-light-bg);
            color: var(--custom-txt-color);
            letter-spacing: 1px;
            margin: 0.2rem 0;
            img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                padding: 0.3rem;
                border-radius: 50%;
            }
            :hover {
                box-shadow: var(--custom-shadow);
            }
        }
    }
`
const BlogComponent = styled.div`
    width: 100%;
    max-width: 1200px;
    position: relative;
    margin: 0rem auto 0;
    height: 89vh;
    /* overflow: auto; */
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    padding: 1rem;

    @media (max-width: 768px) {
    }
    .pageContentOuter {
        height: 89vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`
const BlogOuterWrapper = styled.aside`
    width: 100%;
    max-width: 350px;
    position: absolute;
    left: ${(props) => (props?.show ? `${-350}px` : "0px")};
    top: 80px;
    height: ${(props) => (props?.show ? `${0}vh` : "90vh")};
    @media (max-width: 750px) {
        top: 109px;
        left: ${(props) => (props?.show ? `${-300}px` : "0px")};
        height: ${(props) => (props?.show ? `${0}vh` : "80vh")};
        max-width: 300px;
    }
    .showBlogList {
        position: absolute;
        z-index: 2;
        width: fit-content;
        height: 8rem;
        top: 0%;
        padding: 0.5rem 0.5rem;
        left: 100%;
        background-color: var(--custom-light-bg);
        text-decoration: none;
        display: flex;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-rl;
        border-radius: 0 20px 20px 0;
        &:hover {
            background-color: var(--custom-border-color);
        }
    }
`

export const BlogsScreen = () => {
    const [showLabelList, setShowLabelList] = useState(false)
    const [showBlogList, setShowBlogList] = useState(true)
    const dispatch = useDispatch()
    const singleBlogData = useSelector((state) => state.blogsingle)
    const { blog, loading: singleBlogLoading } = singleBlogData
    const [dontFetch, setDontFetch] = useState(false)
    let AllBlogsDataByLabels = useSelector((state) => state.blogsAllByLabel)
    const {
        AllBlogsData,
        loading: labelListLoading,
        count,
        currentLabel,
    } = AllBlogsDataByLabels
    const { page } = useSelector((state) => state.positionOfBlog)
    const [recordsPerPage] = useState(10)
    const [paginationCurrentPage, setPaginationCurrentPage] = useState(
        page ? page : 1
    )
    useEffect(() => {
        page && setPaginationCurrentPage(page)
    }, [page])

    const loadBlogInfo = (blogId, label) => {
        dispatch(showBlogModal(blogId, label))
    }

    const handleGetBlogLabelList = (labelIdbyLabels) => {
        dispatch(fetchAllBlogsByLabel(labelIdbyLabels))
    }

    const lastPage = Math.ceil(count / recordsPerPage)
        ? Math.ceil(count / recordsPerPage)
        : 0

    return (
        <StyledComponent>
            <TopBar
                setShowLabelList={setShowLabelList}
                showLabelList={showLabelList}
                title="Labels "
                dataList={AllBlogsData}
                LabelsImg={LabelsImg}
            />

            {showLabelList ? (
                <div id="labelNav">
                    <Labels
                        blogListId={AllBlogsData[0]?.label?.id}
                        handleGetBlogLabelList={handleGetBlogLabelList}
                    />
                </div>
            ) : null}
            <BlogOuterWrapper
                show={showBlogList}
                onClick={() => setShowLabelList(false)}
            >
                <BlogListComp
                    AllBlogsDataByLabel={AllBlogsData}
                    blogId={blog?.id}
                    loadBlogInfo={loadBlogInfo}
                    loading={labelListLoading}
                    count={count}
                    currentLabel={currentLabel}
                    recordsPerPage={recordsPerPage}
                    paginationCurrentPage={paginationCurrentPage}
                    setPaginationCurrentPage={setPaginationCurrentPage}
                    dontFetch={dontFetch}
                />
                {AllBlogsData?.length !== 0 && (
                    <Link
                        to=""
                        className="showBlogList"
                        onClick={() => setShowBlogList(!showBlogList)}
                    >
                        BLOG LIST
                    </Link>
                )}
            </BlogOuterWrapper>
            <BlogComponent>
                <BlogComp
                    blog={blog}
                    blogsInfo={AllBlogsData}
                    currentLabel={currentLabel}
                    loadBlogInfo={loadBlogInfo}
                    loading={singleBlogLoading}
                    paginationCurrentPage={paginationCurrentPage}
                    setPaginationCurrentPage={setPaginationCurrentPage}
                    setDontFetch={setDontFetch}
                    lastPage={lastPage}
                />
            </BlogComponent>
        </StyledComponent>
    )
}

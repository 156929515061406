import {
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_FAILED,
    FETCH_USER_PROFILE_START,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_CHANGE_PASS_START,
    USER_CHANGE_PASS_SUCCESS,
    USER_CHANGE_PASS_FAIL,
    USER_RESET_PASS_START,
    USER_RESET_PASS_SUCCESS,
    USER_RESET_PASS_FAIL,
    USER_FORGOT_PASS_START,
    USER_FORGOT_PASS_SUCCESS,
    USER_FORGOT_PASS_FAIL,
    USER_VERIFY_EMAIL_STEP1_START,
    USER_VERIFY_EMAIL_STEP1_SUCCESS,
    USER_VERIFY_EMAIL_STEP1_FAIL,
    USER_VERIFY_EMAIL_STEP2_START,
    USER_VERIFY_EMAIL_STEP2_SUCCESS,
    USER_VERIFY_EMAIL_STEP2_FAIL,
    ALL_USER_FETCH_START,
    ALL_USER_FETCH_SUCCESS,
    ALL_USER_FETCH_FAILED,
} from '../constants/userProfileConstants'
import {
    HIDE_LOGIN_MODAL,
    CONTACT_US_FORM_START,
    CONTACT_US_FORM_SUCCESS,
    CONTACT_US_FORM_FAIL,
} from '../constants/modalConstants'
import { notifySuccess } from '../../helpers/notifications/notifySuccess'

import axios from 'axios'

import {
    patch_profile,
    post_ChangePassword,
    post_resetPasswordStep1,
    post_resetPasswordStep2,
    post_verifyEmailStep1,
    post_verifyEmailStep2,
    post_Login,
    post_Signup,
    get_profile,
    post_ContactUs,
    get_all_users,
} from '../../api/EndPoints'
import axiosInstance from '../../config/api/axois'
import { errorPayload } from '../helper/errorPayload'

export const fetchUserProfile = () => async dispatch => {
    dispatch({ type: FETCH_USER_PROFILE_START, payload: null })

    axiosInstance()
        .get(get_profile())
        .then(response => {
            dispatch({
                type: FETCH_USER_PROFILE_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_USER_PROFILE_FAILED,
                //payload: errorPayload(error),
            })
        })
}

export const register = data => async dispatch => {
    dispatch({
        type: USER_REGISTER_REQUEST,
    })

    axiosInstance()
        .post(post_Signup(), data)
        .then(response => {
            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: response?.data,
            })

            dispatch({
                type: FETCH_USER_PROFILE_SUCCESS,
                payload: response?.data,
            })
            const { refresh, access } = response?.data?.token
            window.localStorage.setItem('access_token', access)
            window.localStorage.setItem('refresh_token', refresh)
            notifySuccess('Registered successfully')

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: response.data,
            })
            notifySuccess('Login successful')
            dispatch({
                type: HIDE_LOGIN_MODAL,
            })
        })
        .catch(error => {
            dispatch({
                type: USER_REGISTER_FAIL,
                payload: errorPayload(error),
            })
        })
}

export const login = data => async dispatch => {
    dispatch({
        type: USER_LOGIN_REQUEST,
    })
    axiosInstance()
        .post(post_Login(), data)
        .then(response => {
            const { refresh, access } = response?.data
            window.localStorage.setItem('access_token', access)
            window.localStorage.setItem('refresh_token', refresh)
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: response?.data,
            })
            notifySuccess('Login successful')

            dispatch({
                type: HIDE_LOGIN_MODAL,
            })
        })
        .catch(error => {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: errorPayload(error),
            })
        })
}

export const editProfile = data => async dispatch => {
    dispatch({
        type: USER_UPDATE_PROFILE_REQUEST,
    })
    axiosInstance()
        .patch(patch_profile(), data)
        .then(response => {
            dispatch({
                type: USER_UPDATE_PROFILE_SUCCESS,
                payload: response.data,
            })
            dispatch({
                type: FETCH_USER_PROFILE_SUCCESS,
                payload: response.data,
            })
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: response?.data,
            })
            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: response?.data,
            })

            notifySuccess('Profile Updated successfully')
            dispatch({
                type: HIDE_LOGIN_MODAL,
            })
        })
        .catch(error => {
            dispatch({
                type: USER_UPDATE_PROFILE_FAIL,
                payload: errorPayload(error),
            })
        })
}

// forgot password
export const forgotPassword = data => async dispatch => {
    dispatch({
        type: USER_FORGOT_PASS_START,
    })
    axiosInstance()
        .post(post_resetPasswordStep1(), data)
        .then(response => {
            dispatch({
                type: USER_FORGOT_PASS_SUCCESS,
                payload: response.data.message,
            })
            notifySuccess(response.data.message)
            dispatch({
                type: HIDE_LOGIN_MODAL,
            })
        })
        .catch(error => {
            dispatch({
                type: USER_FORGOT_PASS_FAIL,
                payload: errorPayload(error),
            })
        })
}
// reset password
export const resetPassword = data => async dispatch => {
    dispatch({
        type: USER_RESET_PASS_START,
    })
    axiosInstance()
        .post(post_resetPasswordStep2(), data)
        .then(response => {
            dispatch({
                type: USER_RESET_PASS_SUCCESS,
                payload: response.data.message,
            })
            notifySuccess(response.data.message)
            dispatch({
                type: HIDE_LOGIN_MODAL,
            })
        })
        .catch(error => {
            dispatch({
                type: USER_RESET_PASS_FAIL,
                payload: errorPayload(error),
            })
        })
}
// verify Email Step 1
export const verifyEmailStep1 = data => async dispatch => {
    dispatch({
        type: USER_VERIFY_EMAIL_STEP1_START,
    })
    axiosInstance()
        .post(post_verifyEmailStep1(), data)
        .then(response => {
            dispatch({
                type: USER_VERIFY_EMAIL_STEP1_SUCCESS,
                payload: response.data.message,
            })
            notifySuccess(response.data.message)
        })
        .catch(error => {
            dispatch({
                type: USER_VERIFY_EMAIL_STEP1_FAIL,
                payload: errorPayload(error),
            })
        })
}
export const verifyEmailStep2 = data => async dispatch => {
    dispatch({
        type: USER_VERIFY_EMAIL_STEP2_START,
    })
    axiosInstance()
        .post(post_verifyEmailStep2(), data)
        .then(response => {
            axiosInstance()
                .get(get_profile())
                .then(response => {
                    dispatch({
                        type: FETCH_USER_PROFILE_SUCCESS,
                        payload: response.data,
                    })
                })
                .catch(error => {
                    const errorPayload = Object.keys(error.response.data).map(
                        item => {
                            if (Array.isArray(error.response.data[item])) {
                                return error.response.data[item][0]
                            } else {
                                return error.response.data[item]
                            }
                        }
                    )
                    dispatch({
                        type: FETCH_USER_PROFILE_FAILED,
                        payload: errorPayload(error),
                    })
                })
            dispatch({
                type: USER_VERIFY_EMAIL_STEP2_SUCCESS,
                payload: response.data.message,
            })
            notifySuccess(response.data.message)
        })
        .catch(error => {
            dispatch({
                type: USER_VERIFY_EMAIL_STEP2_FAIL,
                payload: errorPayload(error),
            })
        })
}
// change Password
export const changePassword = data => async dispatch => {
    dispatch({
        type: USER_CHANGE_PASS_START,
    })
    axiosInstance()
        .post(post_ChangePassword(), data)
        .then(response => {
            dispatch({
                type: USER_CHANGE_PASS_SUCCESS,
                payload: response.data.message,
            })
            notifySuccess(response.data.message)
        })
        .catch(error => {
            dispatch({
                type: USER_CHANGE_PASS_FAIL,
                payload: errorPayload(error),
            })
        })
}
// Contact us form
export const contactus = data => async dispatch => {
    dispatch({
        type: CONTACT_US_FORM_START,
    })

    axios
        .post(post_ContactUs(), data, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'secret-key':
                    'EGuBvmYdYJweYNF_3o3MP_wkuawEpXV5u6H-c7VOSZPqJ3NTSIBPUc3tLKXDKMIEV1H9sjUHBsbW-Pu9',
            },
        })
        .then(response => {
            dispatch({
                type: CONTACT_US_FORM_SUCCESS,
                payload: response.data,
            })
            notifySuccess('Success.')
        })
        .catch(error => {
            dispatch({
                type: CONTACT_US_FORM_FAIL,
                payload: errorPayload(error),
            })
        })
}

//  FETCH USERS
export const getAllUsers =
    (count = 10) =>
    async dispatch => {
        dispatch({
            type: ALL_USER_FETCH_START,
        })

        axiosInstance()
            .get(get_all_users(count))
            .then(response => {
                dispatch({
                    type: ALL_USER_FETCH_SUCCESS,
                    payload: {
                        users: response.data.results,
                        count: response.data.count,
                    },
                })
            })
            .catch(error => {
                dispatch({
                    type: ALL_USER_FETCH_FAILED,
                    payload: errorPayload(error),
                })
            })
    }

import React from "react"
import { useSelector } from "react-redux"
import { ModalComponent } from "../Global/Modal"
import styled from "styled-components"
import { ResetPassword } from "../userprofile/ResetPassword"

const Wrapper = styled.div`
    width: 80%;
    height: 80vh;
    @media (max-width: 700px) {
        width: 100%;
    }
    .grid-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1.5rem;
        background-color: transparent;
        padding: 10px;
        @media (max-width: 1100px) {
            grid-template-columns: auto;
        }
    }

    .grid-container > div {
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        text-align: center;
        padding: 20px;
        font-size: 30px;
    }

    .Update_profile_outer {
        grid-row: 1 / 3;
    }
`

const ProfileModal = () => {
    const modalInfo = useSelector((state) => state.resetPassword)
    const { show } = modalInfo

    return (
        <ModalComponent
            isOpen={show}
            s
            modalLabel={"Example Modal"}
            classNameFromProps="ProfileModal"
        >
            <Wrapper>
                <div className="grid-container">
                    <div className="Update_Password ">
                        <ResetPassword />
                    </div>
                </div>
            </Wrapper>
        </ModalComponent>
    )
}

export default ProfileModal

import {
    FETCH_CONTENT_FAILED,
    FETCH_CONTENT_START,
    FETCH_CONTENT_SUCCESS,
    FETCH_POSITION_FAILED,
    FETCH_POSITION_START,
    FETCH_POSITION_SUCCESS,
} from "../constants/contentConstants"
import AxiosInstance from "../../config/api/axois"
import {
    get_AllBlogsByCategory,
    get_AllPdfBookBySpecial_purpose,
    get_AllPodcast,
    get_blogPosition,
    get_AllNotification,
    get_OneBlog,
} from "../../api/EndPoints"

import { errorPayload } from "../helper/errorPayload"

export const fetchPosition = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_POSITION_START })

        const { data } = await AxiosInstance().get(get_blogPosition(id))

        dispatch({
            type: FETCH_POSITION_SUCCESS,
            payload: data?.location,
        })
    } catch (error) {
        dispatch({
            type: FETCH_POSITION_FAILED,
            payload: errorPayload(error),
        })
    }
}

export const fetchContent =
    (page, section, blogId, paginationCurrentPage, offset) =>
    async (dispatch) => {
        // let singleBlogId
        try {
            var api
            dispatch({
                type: FETCH_CONTENT_START,
                payload: {
                    page: page,
                    section: section,
                },
            })
            if (page === 12) {
                api = `${get_AllNotification(offset)}`
            }

            if (page === 1 || page === 2 || page === 3) {
                // for PDF
                api = `${get_AllPdfBookBySpecial_purpose(
                    page === 1
                        ? "Dashboard of Nash Rambler"
                        : page === 2
                        ? "Esoteric Scrutator"
                        : page === 3 && "General",
                    paginationCurrentPage - 1
                )}`
            } else if (page === 4 || page === 5 || page === 7 || page === 9) {
                // for BLOG LIST

                api = `${get_AllBlogsByCategory(
                    page === 4
                        ? "Ancestral Voices"
                        : page === 5
                        ? "Esoteric Curiosa Blog"
                        : page === 7
                        ? "Foggshire Family Foibles"
                        : page === 9 && "Ruritanian Ramblings",
                    paginationCurrentPage - 1
                )}`
            } else if (page === 6 || page === 8 || page === 10) {
                //    for SINGLE BLOG OPEN

                api = `${get_OneBlog(blogId)}`
            } else if (page === 11) {
                //    for ALL PODCAST

                api = `${get_AllPodcast()}`
            } else if (page === 12) {
                //    for ALL NOIFICATION
                //  api = `${get_AllNotification()}`
            } else {
                api = "error"
            }

            if (api === "error") {
                dispatch({
                    type: FETCH_CONTENT_SUCCESS,
                    payload: {
                        title: "no title for this page",
                        thumbnail: {},
                        publish: false,
                        label: "",
                        category: "",
                        blog: "",
                        count: 0,
                        pageContent: [],
                        page: page,
                        section: section,
                        blogListName: "",
                        blogListId: "",
                    },
                })
            } else {
                if (blogId || (page !== 6 && page !== 8 && page !== 10)) {
                    const { data } = await AxiosInstance().get(api)
                    dispatch({
                        type: FETCH_CONTENT_SUCCESS,
                        payload: {
                            title: data.title && data.title,
                            thumbnail: data.thumbnail && data.thumbnail,
                            publish: data.publish && data.publish,
                            label: data.label && data.label,
                            category: data.category && data.category,
                            blog: data.content && data.content,
                            count: data.count && data.count,
                            pageContent: data.results && data.results,
                            page: page,
                            next: data.next,
                            previous: data.previous,
                            section: section,
                            blogListName: "test list name",
                            blogListId: "test list id",
                        },
                    })
                }
            }
        } catch (error) {
            dispatch({
                type: FETCH_CONTENT_FAILED,
                payload: {
                    //error: errorPayload(error),
                    page: page,
                },
            })
        }
    }

import styled from "styled-components"
import { useEffect, useState } from "react"
import { fetchSinglePodcast } from "../../store/actions/podcastAction"
import { useDispatch, useSelector } from "react-redux"
import { Spinner } from "../Global/Spinner"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
const Wrapper = styled.div`
  border: 1px solid var(--custom-border-color);
  border-radius: 10px;
  padding: 1rem;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1.6rem;
  }
  .rhap_container {
    width: 100%;
    max-width: 40rem;
  }
`
export const PodcastPage = ({ id }) => {
  const dispatch = useDispatch()
  const [currentTrack, setTrackIndex] = useState(0)

  const singlePodcastData = useSelector((state) => state.fetchSinglePodcast)
  const { loading, podcastData } = singlePodcastData
  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    )
  }

  const handleEnd = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    )
  }
  useEffect(() => {
    if (id) {
      dispatch(fetchSinglePodcast(id))
    }
  }, [id, dispatch])
  const playlist = [{ src: podcastData?.content?.file }]
  return (
    <div className='pageContentOuter'>
      <Wrapper>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <h1>{podcastData?.name}</h1>

            <AudioPlayer
              volume='0.5'
              src={playlist[currentTrack].src}
              showSkipControls
              onClickNext={handleClickNext}
              onEnded={handleEnd}
              // Try other props!
            />
          </>
        )}
      </Wrapper>
    </div>
  )
}

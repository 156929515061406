import { forwardRef } from "react"
import styled from "styled-components"
const CoverWrapper = styled.div`
    background-color: var(--custom-orange-color);
    background-image: url(${(props) => props.bgimg});
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    h2 {
        color: transparent;
    }
`
export const PageCover = forwardRef((props, ref) => {
    return (
        <div
            className={"page page-cover page-cover-" + props.pos}
            ref={ref}
            data-density="hard"
        >
            <CoverWrapper bgimg={props?.bgimg}>
                <div className="page-content">
                    <h2>{props?.title}</h2>
                </div>
            </CoverWrapper>
        </div>
    )
})

import {
    FETCH_ALL_LABEL_FAILED,
    FETCH_ALL_LABEL_START,
    FETCH_ALL_LABEL_SUCCESS,
    FETCH_SINGLE_BLOG_START,
    FETCH_SINGLE_BLOG_SUCCESS,
    FETCH_SINGLE_BLOG_FAILED,
    FETCH_ALL_BLOGS_BY_CATEGORY_START,
    FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS,
    FETCH_ALL_BLOGS_BY_CATEGORY_FAILED,
    FETCH_ALL_BLOGS_BY_LABEL_START,
    FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
    FETCH_ALL_BLOGS_BY_LABEL_FAILED,
} from "../constants/blogsConstants"

import AxiosInstance from "../../config/api/axois"
import {
    get_AllBlogsByCategory,
    get_AllBlogsByLabel,
    get_AllLabels,
    get_OneBlog,
} from "../../api/EndPoints"

import { errorPayload } from "../helper/errorPayload"

export const fetchSingleBlog = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLE_BLOG_START })
        const { data } = await AxiosInstance().get(get_OneBlog(id))
        dispatch({
            type: FETCH_SINGLE_BLOG_SUCCESS,
            payload: { blog: data },
        })
    } catch (error) {
        dispatch({
            type: FETCH_SINGLE_BLOG_FAILED,
            //payload: errorPayload(error),
        })
    }
}

export const fetchAllBlogsByCategory = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_BLOGS_BY_CATEGORY_START })
        const { data } = await AxiosInstance().get(get_AllBlogsByCategory()) //category in params
        dispatch({
            type: FETCH_ALL_BLOGS_BY_CATEGORY_SUCCESS,
            payload: { AllBlogsData: data?.results },
        })
    } catch (error) {
        dispatch({
            type: FETCH_ALL_BLOGS_BY_CATEGORY_FAILED,
            //payload: errorPayload(error),
        })
    }
}

export const fetchAllBlogsByLabel =
    (label, currentPage = 1, search = "") =>
    async (dispatch) => {
        try {
            dispatch({ type: FETCH_ALL_BLOGS_BY_LABEL_START })
            const { data } = await AxiosInstance().get(
                get_AllBlogsByLabel(
                    label,
                    currentPage * 10 - 10,
                    search ? search : ""
                )
            )
            dispatch({
                type: FETCH_ALL_BLOGS_BY_LABEL_SUCCESS,
                payload: {
                    AllBlogsData: data?.results,
                    count: data?.count,
                    currentLabel: label,
                },
            })
        } catch (error) {
            dispatch({
                type: FETCH_ALL_BLOGS_BY_LABEL_FAILED,
                //payload: errorPayload(error),
            })
        }
    }

export const fetchLabels = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_LABEL_START })
        const { data } = await AxiosInstance().get(get_AllLabels())

        dispatch({
            type: FETCH_ALL_LABEL_SUCCESS,
            payload: { AllLabels: data.results },
        })
    } catch (error) {
        dispatch({
            type: FETCH_ALL_LABEL_FAILED,
            // payload: errorPayload(error),
        })
    }
}
